import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';

const ExhibitorItinerary = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get('/rotr/exhibitorItinerary/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item container xs={12} md={12} id='EXHIBITOR_ITINERARY_ROW1_COL1' />
      <Grid item container xs={12} md={12} id='EXHIBITOR_ITINERARY_ROW2_COL1' />
    </Grid>
  );
};

export default ExhibitorItinerary;

import { Close, Delete, Image } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import FileUploadButton from './Components/UploadFile';
import ConfirmModal from './Components/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { setExhibitors } from '../../../Redux/ExhibitorSlice';

const MarketingOpportunities = () => {
  const [content, setContent] = useState([]);
  const userInformation = useSelector((state) => state.userInformation.value);
  const exhibitors = useSelector((state) => state.exhibitors.value);
  const [selectedExhibitorId, setSelectedExhibitorId] = useState(null);
  const [addons, setAddons] = useState(null);
  const [customArtworkAddons, setCustomArtworkAddons] = useState([]);
  const [noCustomArtworkAddons, setNoCustomArtworkAddons] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    axios
      .get('/rotr/marketingOpportunities/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post('/rotr/getExhibitors', null, {
        params: { accessCode: userInformation.accessCode },
      })
      .then((res) => {
        dispatch(setExhibitors(res.data));

        if (res.data.length === 1) {
          setSelectedExhibitorId(res.data[0].id);
        }
      })
      .catch((err) => {
        console.error('Failed to fetch exhibitors:', err);
      });
  }, [dispatch, userInformation.accessCode]);

  useEffect(() => {
    if (selectedExhibitorId !== null) {
      axios
        .get('/rotr/marketingOpportunities', {
          params: {
            exhibitorId: selectedExhibitorId,
          },
        })
        .then((res) => {
          setAddons(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedExhibitorId]);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  useEffect(() => {
    if (Array.isArray(addons)) {
      setCustomArtworkAddons(addons.filter((addon) => addon.requires_artwork));
      setNoCustomArtworkAddons(addons.filter((addon) => !addon.requires_artwork));
    }
  }, [addons]);

  const handleFileSelect = (newFile, newFileName, selectedAddon, selectedArtwork) => {
    setNewFile(newFile);
    setNewFileName(newFileName);
    setSelectedAddon(selectedAddon);
    setSelectedArtwork(selectedArtwork);
  };

  const handleUploadModalClose = () => {
    setNewFile(null);
  };

  const handleUploadModalAfterClose = () => {
    setNewFileName('');
    setSelectedAddon(null);
    setSelectedArtwork(null);
  };

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  const handleUploadModalConfirm = async (handleUploadModalClose) => {
    try {
      setOpenSnackbar(false);

      if (!newFile) {
        setTimeout(() => {
          setSnackbarSeverity('error');
          setSnackbarMessage('No file selected');
          setOpenSnackbar(true);
        }, 250);
        return;
      }

      const safeExhibitorName = selectedAddon.exhibitor.replace(/[^a-zA-Z]/g, '');
      const safeAddonDescription = selectedAddon.addon_description.replace(/[^a-zA-Z]/g, '');
      const randomString = generateRandomString(15);
      const fileExtension = newFileName.includes('.') ? `.${newFileName.split('.').pop()}` : '';
      const internalFilename = `${safeExhibitorName}-${safeAddonDescription}-${randomString}${fileExtension}`;

      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('fileName', internalFilename);
      formData.append('exhibitorAddonLinkId', selectedAddon.exhibitor_addon_link_id);
      formData.append('displayName', newFileName);
      if (selectedArtwork) {
        formData.append('mediaAssetId', selectedArtwork.id);
      }

      const response = await axios.post(`/upload/rotr`, formData);
      const addonIndex = addons.findIndex(
        (addon) => addon.exhibitor_addon_link_id === selectedAddon.exhibitor_addon_link_id
      );

      if (selectedArtwork) {
        // Replace existing artwork
        const artworkIndex = selectedAddon.artwork.findIndex(
          (artwork) => artwork.id === selectedArtwork.id
        );
        setAddons((prevAddons) => {
          const newAddons = [...prevAddons];
          newAddons[addonIndex] = { ...selectedAddon, artwork: [...selectedAddon.artwork] };
          newAddons[addonIndex].artwork[artworkIndex] = {
            ...selectedArtwork,
            artwork_link: response.data.uploadedFilePath,
            custom_name: newFileName,
          };
          return newAddons;
        });
      } else {
        // Add new artwork
        const artworkIndex = selectedAddon.artwork.findIndex((artwork) => artwork === null);
        setAddons((prevAddons) => {
          const newAddons = [...prevAddons];
          newAddons[addonIndex] = { ...selectedAddon, artwork: [...selectedAddon.artwork] };
          newAddons[addonIndex].artwork[artworkIndex] = {
            id: response.data.lastInsertId,
            artwork_link: response.data.uploadedFilePath,
            custom_name: newFileName,
          };
          return newAddons;
        });
      }

      setTimeout(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('File successfully uploaded');
        setOpenSnackbar(true);
      }, 250);

      handleUploadModalClose();
    } catch (error) {
      setTimeout(() => {
        setSnackbarSeverity('error');
        setSnackbarMessage('An error occurred while uploading the file');
        setOpenSnackbar(true);
      }, 250);

      handleUploadModalClose();
    }
  };

  const handleDeleteClick = (selectedAddon, selectedArtwork) => {
    setOpenDelete(true);
    setSelectedAddon(selectedAddon);
    setSelectedArtwork(selectedArtwork);
  };

  const handleDeleteModalClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteModalAfterClose = () => {
    setSelectedAddon(null);
    setSelectedArtwork(null);
  };

  const handleDeleteModalConfirm = async (handleDeleteModalClose) => {
    try {
      setOpenSnackbar(false);

      if (!selectedArtwork) {
        setTimeout(() => {
          setSnackbarSeverity('error');
          setSnackbarMessage('No file selected');
          setOpenSnackbar(true);
        }, 250);
        return;
      }

      await axios.put('/rotr/marketingOpportunities', {
        artworkId: selectedArtwork.id,
      });
      const addonIndex = addons.findIndex(
        (addon) => addon.exhibitor_addon_link_id === selectedAddon.exhibitor_addon_link_id
      );

      // Delete existing artwork
      const artworkIndex = selectedAddon.artwork.findIndex(
        (artwork) => artwork.id === selectedArtwork.id
      );
      setAddons((prevAddons) => {
        const newAddons = [...prevAddons];
        newAddons[addonIndex] = {
          ...selectedAddon,
          artwork: [
            ...selectedAddon.artwork.slice(0, artworkIndex),
            ...selectedAddon.artwork.slice(artworkIndex + 1, selectedAddon.artwork.length),
            null,
          ],
        };
        return newAddons;
      });

      setTimeout(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('File successfully deleted');
        setOpenSnackbar(true);
      }, 250);

      handleDeleteModalClose();
    } catch (error) {
      setTimeout(() => {
        setSnackbarSeverity('error');
        setSnackbarMessage('An error occurred while deleting the file');
        setOpenSnackbar(true);
      }, 250);

      handleDeleteModalClose();
    }
  };

  const handleViewClick = (event, imageUrl) => {
    setImageLoading(true);
    setImage(imageUrl);
    setShowImage(true);
  };

  const handleImageModalClose = () => {
    setShowImage(false);
    setImageLoading(false);
    setImage(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item container xs={12} id='MARKETING_OPPORTUNITIES_ROW1_COL1' />
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          {exhibitors.length > 1 && (
            <Autocomplete
              options={exhibitors}
              getOptionLabel={(option) => option.exhibitor_name}
              value={exhibitors.find((exhibitor) => exhibitor.id === selectedExhibitorId) ?? null}
              onChange={(event, exhibitor) => {
                setSelectedExhibitorId(exhibitor?.id ?? null);
                setAddons(null);
              }}
              renderInput={(params) => <TextField {...params} label='Select Exhibitor' />}
              sx={{ maxWidth: '300px', width: '100%', mb: 4 }}
            />
          )}
          {exhibitors.length === 0 && (
            <Typography
              variant='h5'
              component='div'
              sx={{
                fontStyle: 'italic',
                color: '#222',
              }}
            >
              You need to register in order to view your marketing opportunities!
            </Typography>
          )}
        </Grid>
        {selectedExhibitorId !== null &&
          (addons ? (
            addons.length > 0 ? (
              <Grid item container xs={12} spacing={8}>
                {/* Table 1: Addons with Custom Artwork */}
                <Grid item xs={12}>
                  {!isMobile ? (
                    <>
                      {/* DESKTOP VIEW */}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography
                          variant='h5'
                          sx={{
                            mb: 2,
                            mx: 1,
                            fontFamily: 'League Spartan',
                            borderBottom: `2px solid ${theme.palette.rendezvousRed.main}`,
                          }}
                        >
                          Addons with Custom Artwork
                        </Typography>
                      </Box>
                      {customArtworkAddons.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Addon</Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Description</Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Example</Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Due Date</Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Template</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body1'>Custom Artwork</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customArtworkAddons.map((addon, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    <Typography variant='body1'>
                                      {addon.addon_description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    <Typography variant='body1'>
                                      {addon.detailed_description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    {addon.image_url ? (
                                      <Button
                                        variant='contained'
                                        onClick={(event) => handleViewClick(event, addon.image_url)}
                                        startIcon={<Image />}
                                        color='rendezvousRed'
                                      >
                                        View
                                      </Button>
                                    ) : (
                                      <Typography variant='body1'>No image available</Typography>
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    <Typography variant='body1'>
                                      {addon.due_date || 'N/A'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    {addon.template ? (
                                      <Link
                                        href={addon.template}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        variant='body1'
                                        sx={{
                                          color: 'blue',
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        Template
                                      </Link>
                                    ) : (
                                      <Typography variant='body1'>No template available</Typography>
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ maxWidth: '250px' }}>
                                    {addon.requires_artwork &&
                                      addon.artwork &&
                                      addon.artwork.map((artwork, artworkIndex) => (
                                        <React.Fragment key={artworkIndex}>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              gap: 1,
                                            }}
                                          >
                                            {artwork ? (
                                              <>
                                                <Tooltip
                                                  title={artwork.custom_name}
                                                  placement='top'
                                                  disableInteractive
                                                  slotProps={{
                                                    tooltip: { sx: { fontSize: '12px' } },
                                                  }}
                                                >
                                                  <Link
                                                    href={artwork.artwork_link}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    variant='body1'
                                                    sx={{
                                                      color: 'blue',
                                                      textDecoration: 'underline',
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {artwork.custom_name}
                                                  </Link>
                                                </Tooltip>
                                                <Box sx={{ display: 'flex', gap: 0.5 }}>
                                                  <IconButton
                                                    color='error'
                                                    onClick={() =>
                                                      handleDeleteClick(addon, artwork)
                                                    }
                                                  >
                                                    <Delete />
                                                  </IconButton>
                                                  <FileUploadButton
                                                    onFileSelect={(newFile, newFileName) =>
                                                      handleFileSelect(
                                                        newFile,
                                                        newFileName,
                                                        addon,
                                                        artwork
                                                      )
                                                    }
                                                  />
                                                </Box>
                                              </>
                                            ) : (
                                              <>
                                                <Typography variant='body1'>
                                                  No file uploaded
                                                </Typography>
                                                <FileUploadButton
                                                  onFileSelect={(newFile, newFileName) =>
                                                    handleFileSelect(
                                                      newFile,
                                                      newFileName,
                                                      addon,
                                                      null
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </Box>
                                          {artworkIndex < addon.artwork.length - 1 && (
                                            <Divider sx={{ mx: '-5px', my: 0.5 }} />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Box sx={{ my: 2, ml: 2 }}>
                          <Typography variant='subtitle' fontSize='18px'>
                            No addons found
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {/* MOBILE VIEW */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: 'rendezvousRed.main',
                          p: 1,
                          mb: 2,
                          mx: 1,
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant='h5'
                          sx={{
                            fontFamily: 'League Spartan',
                            color: 'rendezvousRed.contrastText',
                          }}
                        >
                          Addons with Custom Artwork
                        </Typography>
                      </Box>
                      <Grid container spacing={2}>
                        {customArtworkAddons.length > 0 ? (
                          customArtworkAddons.map((addon, index) => (
                            <Grid item xs={12} key={index}>
                              <Card elevation={4}>
                                <CardContent
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '90%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant='title'
                                      align='center'
                                      sx={{ fontSize: '22px', my: 1.5 }}
                                    >
                                      {addon.addon_description}
                                    </Typography>
                                    <Typography
                                      variant='subtitle'
                                      align='center'
                                      sx={{ fontSize: '18px', mb: 3 }}
                                    >
                                      {addon.detailed_description}
                                    </Typography>

                                    {addon.image_url ? (
                                      <Button
                                        variant='contained'
                                        onClick={(event) => handleViewClick(event, addon.image_url)}
                                        startIcon={<Image />}
                                        color='rendezvousRed'
                                        sx={{ mb: 4 }}
                                      >
                                        View Example
                                      </Button>
                                    ) : (
                                      <Typography variant='body1' mb={4}>
                                        No example image available
                                      </Typography>
                                    )}
                                  </Box>

                                  {addon.requires_artwork && addon.artwork && (
                                    <Box
                                      sx={{
                                        border: '1px solid lightgray',
                                        borderRadius: '5px',
                                        backgroundColor: '#f5f5f5',
                                        padding: '10px',
                                        mt: 2,
                                        width: '90%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        variant='title'
                                        sx={{ fontSize: '22px', mt: 1.5, mb: 0.5 }}
                                      >
                                        Custom Artwork
                                      </Typography>

                                      <Typography variant='body1' sx={{ mt: 1 }}>
                                        <b>Due Date:</b> {addon.due_date || 'N/A'}
                                      </Typography>

                                      {addon.template ? (
                                        <Link
                                          href={addon.template}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          variant='body1'
                                          sx={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            display: 'block',
                                            mt: 1,
                                          }}
                                        >
                                          View Template
                                        </Link>
                                      ) : (
                                        <Typography variant='body1' mt={1}>
                                          No template available
                                        </Typography>
                                      )}

                                      <Divider sx={{ width: '90%', mx: '-5px', my: 2.5 }} />

                                      <Box maxWidth='90%'>
                                        {addon.artwork.map((artwork, artworkIndex) => (
                                          <React.Fragment key={artworkIndex}>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              {artwork ? (
                                                <>
                                                  <Tooltip
                                                    title={artwork.custom_name}
                                                    placement='top'
                                                    disableInteractive
                                                    slotProps={{
                                                      tooltip: { sx: { fontSize: '12px' } },
                                                    }}
                                                  >
                                                    <Link
                                                      href={artwork.artwork_link}
                                                      target='_blank'
                                                      rel='noopener noreferrer'
                                                      variant='body1'
                                                      sx={{
                                                        color: 'blue',
                                                        textDecoration: 'underline',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                      }}
                                                    >
                                                      {artwork.custom_name}
                                                    </Link>
                                                  </Tooltip>
                                                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                                                    <IconButton
                                                      color='error'
                                                      onClick={() =>
                                                        handleDeleteClick(addon, artwork)
                                                      }
                                                    >
                                                      <Delete />
                                                    </IconButton>
                                                    <FileUploadButton
                                                      onFileSelect={(newFile, newFileName) =>
                                                        handleFileSelect(
                                                          newFile,
                                                          newFileName,
                                                          addon,
                                                          artwork
                                                        )
                                                      }
                                                    />
                                                  </Box>
                                                </>
                                              ) : (
                                                <>
                                                  <Typography variant='body1'>
                                                    No file uploaded
                                                  </Typography>
                                                  <FileUploadButton
                                                    onFileSelect={(newFile, newFileName) =>
                                                      handleFileSelect(
                                                        newFile,
                                                        newFileName,
                                                        addon,
                                                        null
                                                      )
                                                    }
                                                  />
                                                </>
                                              )}
                                            </Box>
                                            {artworkIndex < addon.artwork.length - 1 && (
                                              <Divider sx={{ mx: '-5px', my: 0.5 }} />
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </Box>
                                    </Box>
                                  )}
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center', my: 2 }}>
                              <Typography variant='title' sx={{ fontSize: '22px' }}>
                                No addons found
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>

                {/* Table 2: Basic Addons */}
                <Grid item xs={12}>
                  {!isMobile ? (
                    <>
                      {/* DESKTOP VIEW */}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography
                          variant='h5'
                          sx={{
                            mb: 2,
                            mx: 1,
                            fontFamily: 'League Spartan',
                            borderBottom: `2px solid ${theme.palette.rendezvousRed.main}`,
                          }}
                        >
                          Basic Addons
                        </Typography>
                      </Box>
                      {noCustomArtworkAddons.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Addon</Typography>
                                </TableCell>
                                <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                  <Typography variant='body1'>Description</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body1'>Example</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {noCustomArtworkAddons.map((addon, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    <Typography variant='body1'>
                                      {addon.addon_description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ borderRight: '1px solid lightgrey' }}>
                                    <Typography variant='body1'>
                                      {addon.detailed_description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {addon.image_url ? (
                                      <Button
                                        variant='contained'
                                        onClick={(event) => handleViewClick(event, addon.image_url)}
                                        startIcon={<Image />}
                                        color='rendezvousRed'
                                      >
                                        View
                                      </Button>
                                    ) : (
                                      <Typography variant='body1'>No image available</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Box sx={{ my: 2, ml: 2 }}>
                          <Typography variant='subtitle' fontSize='18px'>
                            No addons found
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {/* MOBILE VIEW */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: 'rendezvousRed.main',
                          p: 1,
                          mb: 2,
                          mx: 1,
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant='h5'
                          sx={{
                            fontFamily: 'League Spartan',
                            color: 'rendezvousRed.contrastText',
                          }}
                        >
                          Basic Addons
                        </Typography>
                      </Box>
                      <Grid container spacing={2}>
                        {noCustomArtworkAddons.length > 0 ? (
                          noCustomArtworkAddons.map((addon, index) => (
                            <Grid item xs={12} key={index}>
                              <Card elevation={4}>
                                <CardContent
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '90%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant='title'
                                      align='center'
                                      sx={{ fontSize: '22px', my: 1.5 }}
                                    >
                                      {addon.addon_description}
                                    </Typography>
                                    <Typography
                                      variant='subtitle'
                                      align='center'
                                      sx={{ fontSize: '18px', mb: 3 }}
                                    >
                                      {addon.detailed_description}
                                    </Typography>

                                    {addon.image_url ? (
                                      <Button
                                        variant='contained'
                                        onClick={(event) => handleViewClick(event, addon.image_url)}
                                        startIcon={<Image />}
                                        color='rendezvousRed'
                                      >
                                        View Example
                                      </Button>
                                    ) : (
                                      <Typography variant='body1'>
                                        No example image available
                                      </Typography>
                                    )}
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center', my: 2 }}>
                              <Typography variant='title' sx={{ fontSize: '22px' }}>
                                No addons found
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} textAlign='center'>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{
                    fontStyle: 'italic',
                    color: '#222',
                  }}
                >
                  No addons found for this exhibitor!
                </Typography>
              </Grid>
            )
          ) : (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              {/* Loader to display while addons are loading */}
              <CircularProgress sx={{ mt: 2 }} />
            </Grid>
          ))}
      </Grid>

      {/* Modal for displaying images */}
      <Modal
        open={showImage}
        onClose={handleImageModalClose}
        keepMounted
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 1,
            maxWidth: '75%',
            maxHeight: '75%',
            backgroundColor: 'white',
            display: 'flex',
            borderRadius: '5px',
            position: 'relative',
            opacity: showImage ? 1 : 0,
            transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          {imageLoading && <CircularProgress />}
          {showImage && (
            <img
              src={image}
              alt={image}
              style={{ width: '100%', height: '100%', ...(imageLoading && { display: 'none' }) }}
              onLoad={() => setImageLoading(false)}
            />
          )}
          <IconButton
            onClick={handleImageModalClose}
            sx={{
              position: 'absolute',
              right: '-40px',
              top: '-40px',
              backgroundColor: 'rendezvousRed.main',
              color: 'rendezvousRed.contrastText',
              ':hover': { backgroundColor: 'rendezvousRed.dark' },
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </Modal>

      {/* Modal for confirming uploads/replacements */}
      <ConfirmModal
        open={!!newFile}
        handleClose={handleUploadModalClose}
        handleAfterClose={handleUploadModalAfterClose}
        handleConfirm={handleUploadModalConfirm}
        title={selectedArtwork ? 'Confirm File Replacement' : 'Confirm File Upload'}
        contentText={
          selectedArtwork
            ? `Are you sure you want to replace the file "${selectedArtwork.custom_name}" with the file "${newFileName}"?`
            : `Are you sure you want to upload the file "${newFileName}"?`
        }
      />

      {/* Modal for confirming deletions */}
      <ConfirmModal
        open={openDelete}
        handleClose={handleDeleteModalClose}
        handleAfterClose={handleDeleteModalAfterClose}
        handleConfirm={handleDeleteModalConfirm}
        title='Confirm File Deletion'
        contentText={`Are you sure you want to delete the file "${selectedArtwork?.custom_name}"?`}
      />

      {/* Snackbar for conveying successes/errors to user */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MarketingOpportunities;

import { createSlice } from '@reduxjs/toolkit';

export const ExhibitorRegistrationSlice = createSlice({
  name: 'registration',
  initialState: {
    contentStep: 0,
    exhibitorId: '',
    companyName: '',
    companyNameError: false,
    companyEmail: '',
    companyEmailError: false,
    companyPhone: '',
    companyPhoneError: false,
    companyWebsite: '',
    boothNumber: '',
    boothPrice: '',
    boothType: '',
    paymentEmail: '',
    paymentNotes: '',
    paymentTotal: '',
    paymentType: '',
    addons: [],
    stepContent: {},
    isEditing: false,
  },
  reducers: {
    setExhibitorRegistration: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearExhibitorRegistration: (state) => {
      return {
        contentStep: 0,
        exhibitorId: '',
        companyName: '',
        companyNameError: false,
        companyEmail: '',
        companyEmailError: false,
        companyPhone: '',
        companyPhoneError: false,
        companyWebsite: '',
        boothNumber: '',
        boothPrice: '',
        boothType: '',
        paymentEmail: '',
        paymentNotes: '',
        paymentTotal: '',
        paymentType: '',
        addons: [],
        stepContent: {},
        isEditing: false,
      };
    },
    addAddon: (state, action) => {
      const addon = action.payload;
      const existingAddon = state.addons.find((item) => item.addon_id === addon.addon_id);
      if (existingAddon) {
        existingAddon.quantity += addon.quantity;
      } else {
        state.addons.push(addon);
      }
    },
    removeAddon: (state, action) => {
      state.addons = state.addons.filter((item) => item.addon_id !== action.payload.addon_id);
    },
    updateAddonQuantity: (state, action) => {
      const { addon_id, quantity } = action.payload;
      const existingAddon = state.addons.find((item) => item.addon_id === addon_id);
      if (existingAddon) {
        existingAddon.quantity = quantity;
      }
    },
  },
});

export const {
  setExhibitorRegistration,
  clearExhibitorRegistration,
  addAddon,
  removeAddon,
  updateAddonQuantity,
} = ExhibitorRegistrationSlice.actions;
export default ExhibitorRegistrationSlice.reducer;

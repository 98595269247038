import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add,
  CheckCircle,
  Delete,
  Edit,
  ExpandMore,
  Image,
  Save,
  Visibility,
  AccountBox,
} from '@mui/icons-material';
import MaskedInput from 'react-text-mask';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../../../Redux/ModalSlice';

const ERegistrationTableDesktop = ({
  badgeErrors,
  editingRows,
  handleViewRegistrationModal,
  handleBoothEdit,
  handleDeleteClick,
  handleAddPersonnel,
  handleBadgeModal,
  handleEdit,
  deletePersonnel,
  handlePersonnelInputChange,
  updateBadgeError,
  updateBadgeEmailError,
  updateBadgePhoneError,
  validateBadgeData,
  handleSave,
  accordionExpanded,
  setAccordionExpanded,
  states,
  handlePersonnelStateChange,
  handleProfileExplanationModal,
}) => {
  const exhibitors = useSelector((state) => state.exhibitors.value);
  const highlightPersonnelAdd = useSelector((state) => state.modalStatus.exhibitorBadges);
  const dispatch = useDispatch();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        width: '100%',
        backgroundColor: '#f8f9fa',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      {exhibitors.map((brand, index) => (
        <Table
          size='small'
          sx={{
            mb: index === exhibitors.length - 1 ? 0 : 2,
            outline: `solid 2px ${alpha('#00325a', 0.5)}`,
            backgroundColor: 'white',
          }}
          key={brand.id}
        >
          <TableHead>
            <TableRow sx={{ outline: '1px solid #ccc' }}>
              <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  Booth: {brand.booth_number}
                </Typography>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    backgroundColor: 'green',
                    color: 'white',
                    borderRadius: 1,
                    px: 1,
                  }}
                >
                  <CheckCircle sx={{ fontSize: 'small', mr: 0.5 }} />
                  <Typography variant='body2'>Confirmed</Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  Exhibitor Company Name
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  Company Phone #
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  Company Email
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  borderLeft: 'solid 1px lightgrey',
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                  Company Website
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& tr:nth-last-of-type(1) td': {
                borderBottom: 'none',
              },
              '& td:not(:first-of-type)': {
                wordBreak: 'break-word',
              },
            }}
          >
            <TableRow sx={{ outline: '1px solid #ccc' }}>
              <TableCell sx={{ padding: '16px' }}>
                <Box display='flex' justifyContent='center' alignItems='center' columnGap={1}>
                  <IconButton
                    onClick={() => {
                      handleProfileExplanationModal(brand.booth_edit_link);
                    }}
                  >
                    <Stack alignItems='center'>
                      <AccountBox />
                      <Typography variant='caption'>PROFILE</Typography>
                    </Stack>
                  </IconButton>
                  <IconButton onClick={() => handleViewRegistrationModal(brand.id)}>
                    <Stack alignItems='center'>
                      <Visibility />
                      <Typography variant='caption'>VIEW</Typography>
                    </Stack>
                  </IconButton>
                  <IconButton
                    onClick={() => handleBoothEdit(brand.id)}
                    disabled={brand.allow_edit === 0}
                  >
                    <Stack alignItems='center'>
                      <Edit />
                      <Typography variant='caption'>EDIT</Typography>
                    </Stack>
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(brand.id)}>
                    <Stack alignItems='center'>
                      <Delete />
                      <Typography variant='caption'>DELETE</Typography>
                    </Stack>
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell>
                <Typography variant='body1' textAlign='center'>
                  {brand.exhibitor_name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1' textAlign='center'>
                  {brand.exhibitor_phone}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1' textAlign='center'>
                  {brand.exhibitor_email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1' textAlign='center'>
                  {brand.exhibitor_website}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                <Box my={2}>
                  <Accordion
                    expanded={accordionExpanded.includes(brand.id)}
                    onClick={() => dispatch(setModalStatus({ exhibitorBadges: false }))}
                    onChange={(e, isExpanded) =>
                      setAccordionExpanded((prev) => {
                        if (isExpanded) {
                          return [...prev, brand.id];
                        } else {
                          const i = prev.indexOf(brand.id);
                          return [...prev.slice(0, i), ...prev.slice(i + 1)];
                        }
                      })
                    }
                    sx={{
                      '@keyframes blinkOutline': {
                        '0%': {
                          outline: '2px solid rgba(255, 0, 0, 0)',
                        },
                        '50%': {
                          outline: '2px solid rgba(255, 0, 0, 1)',
                        },
                        '100%': {
                          outline: '2px solid rgba(255, 0, 0, 0)',
                        },
                      },
                      animation: highlightPersonnelAdd ? 'blinkOutline 2s infinite' : 'none',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography
                        variant='body1'
                        color='primary.main'
                        textTransform='uppercase'
                        textAlign='center'
                      >
                        Manage Booth Personnel (badges)
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Divider sx={{ mt: -1, mb: 1 }} />
                        <Button
                          variant='contained'
                          onClick={() => handleAddPersonnel(brand.id)}
                          startIcon={<Add />}
                        >
                          Add Booth Personnel (badges)
                        </Button>
                        {brand.personnel.length > 0 && (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                columnGap: 1,
                                mt: 2,
                                pb: 2,
                              }}
                            >
                              <Typography variant='body1'>
                                Required columns are marked with (*) when editing.
                              </Typography>
                              <Typography variant='body1' color='error.main'>
                                If you are adding multiple booths, only include yourself on one of
                                the booths.
                              </Typography>
                            </Box>
                            <Table
                              id={`table_${brand.id}`}
                              size='small'
                              sx={{
                                mt: 2,
                                '& tbody tr td': {
                                  px: 0.5,
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell colSpan={6}>
                                    <Stack
                                      direction='row'
                                      justifyContent='center'
                                      gap={1}
                                      textAlign='center'
                                    >
                                      <Typography
                                        variant='body1'
                                        sx={{ color: '#00325A', fontWeight: 'bold' }}
                                      >
                                        ↓
                                      </Typography>
                                      <Typography
                                        variant='body1'
                                        sx={{ color: '#00325A', fontWeight: 'bold' }}
                                      >
                                        This info will be printed on badge
                                      </Typography>
                                      <Typography
                                        variant='body1'
                                        sx={{ color: '#00325A', fontWeight: 'bold' }}
                                      >
                                        ↓
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell colSpan={2}>
                                    <Stack
                                      direction='row'
                                      justifyContent='center'
                                      gap={1}
                                      textAlign='center'
                                    >
                                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                        ↓
                                      </Typography>
                                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                        This info is not public
                                      </Typography>
                                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                        ↓
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  {brand.personnel.some((person) =>
                                    editingRows.includes(person.badge_id)
                                  ) && <TableCell />}
                                </TableRow>
                                <TableRow>
                                  <TableCell />
                                  <TableCell
                                    sx={{
                                      backgroundColor: '#00325A',
                                      textAlign: 'center',
                                      borderTopLeftRadius: '4px',
                                      borderBottomLeftRadius: '4px',
                                    }}
                                  >
                                    <Typography
                                      variant='body1'
                                      sx={{ color: 'white', fontWeight: 'bold' }}
                                    >
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      )
                                        ? 'First Name *'
                                        : 'First Name'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ backgroundColor: '#00325A', textAlign: 'center' }}
                                  >
                                    <Typography
                                      variant='body1'
                                      sx={{ color: 'white', fontWeight: 'bold' }}
                                    >
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      )
                                        ? 'Last Name *'
                                        : 'Last Name'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ backgroundColor: '#00325A', textAlign: 'center' }}
                                  >
                                    <Typography
                                      variant='body1'
                                      sx={{ color: 'white', fontWeight: 'bold' }}
                                    >
                                      Job Title
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ backgroundColor: '#00325A', textAlign: 'center' }}
                                  >
                                    <Typography
                                      variant='body1'
                                      sx={{ color: 'white', fontWeight: 'bold' }}
                                    >
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      )
                                        ? 'Company Name *'
                                        : 'Company Name'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'center', backgroundColor: '#00325A' }}
                                  >
                                    <Typography
                                      variant='body1'
                                      sx={{ color: 'white', fontWeight: 'bold' }}
                                    >
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      )
                                        ? 'City *'
                                        : 'City'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: 'center',
                                      backgroundColor: '#00325A',
                                      borderBottomRightRadius: '4px',
                                      borderTopRightRadius: '4px',
                                    }}
                                  >
                                    <Typography
                                      variant='body1'
                                      sx={{ color: 'white', fontWeight: 'bold' }}
                                    >
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      )
                                        ? 'State *'
                                        : 'State'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'center', backgroundColor: 'lightgray' }}
                                  >
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                      {brand.personnel.some((person) =>
                                        editingRows.includes(person.badge_id)
                                      )
                                        ? 'Email Address *'
                                        : 'Email Address'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'center', backgroundColor: 'lightgray' }}
                                  >
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                      Cell Phone #
                                    </Typography>
                                  </TableCell>
                                  {brand.personnel.some((person) =>
                                    editingRows.includes(person.badge_id)
                                  ) && <TableCell />}
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  '& tr:nth-last-of-type(1) td': {
                                    borderBottom: 'none',
                                  },
                                  '& td:not(:first-of-type)': {
                                    wordBreak: 'break-word',
                                  },
                                }}
                              >
                                {brand.personnel.map((person) => (
                                  <TableRow key={person.badge_id}>
                                    <TableCell sx={{ padding: '16px' }}>
                                      <Box
                                        display='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                        columnGap={1}
                                      >
                                        <IconButton onClick={() => handleBadgeModal(person)}>
                                          <Stack alignItems='center'>
                                            <Image />
                                            <Typography variant='caption'>PREVIEW</Typography>
                                          </Stack>
                                        </IconButton>
                                        {!editingRows.includes(person.badge_id) && (
                                          <IconButton onClick={() => handleEdit(person.badge_id)}>
                                            <Stack alignItems='center'>
                                              <Edit />
                                              <Typography variant='caption'>EDIT</Typography>
                                            </Stack>
                                          </IconButton>
                                        )}
                                        <IconButton
                                          onClick={() => deletePersonnel(brand.id, person.badge_id)}
                                        >
                                          <Stack alignItems='center'>
                                            <Delete />
                                            <Typography variant='caption'>DELETE</Typography>
                                          </Stack>
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <TextField
                                          placeholder='First Name'
                                          value={person.first_name ?? ''}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'first_name',
                                              e.target.value
                                            )
                                          }
                                          required
                                          error={!!badgeErrors[person.badge_id]?.first_name}
                                          onBlur={(e) =>
                                            updateBadgeError(
                                              person.badge_id,
                                              'first_name',
                                              e.target.value,
                                              true
                                            )
                                          }
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.first_name}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <TextField
                                          placeholder='Last Name'
                                          value={person.last_name ?? ''}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'last_name',
                                              e.target.value
                                            )
                                          }
                                          required
                                          error={!!badgeErrors[person.badge_id]?.last_name}
                                          onBlur={(e) =>
                                            updateBadgeError(
                                              person.badge_id,
                                              'last_name',
                                              e.target.value,
                                              true
                                            )
                                          }
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.last_name}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <TextField
                                          placeholder='Job Title'
                                          value={person.job_title ?? ''}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'job_title',
                                              e.target.value
                                            )
                                          }
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.job_title}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <TextField
                                          placeholder='Company Name'
                                          value={person.company_name ?? ''}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'company_name',
                                              e.target.value
                                            )
                                          }
                                          required
                                          error={!!badgeErrors[person.badge_id]?.company_name}
                                          onBlur={(e) =>
                                            updateBadgeError(
                                              person.badge_id,
                                              'company_name',
                                              e.target.value,
                                              true
                                            )
                                          }
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.company_name}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <TextField
                                          placeholder='City'
                                          value={person.city ?? ''}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'city',
                                              e.target.value
                                            )
                                          }
                                          required
                                          error={!!badgeErrors[person.badge_id]?.city}
                                          onBlur={(e) =>
                                            updateBadgeError(
                                              person.badge_id,
                                              'city',
                                              e.target.value,
                                              true
                                            )
                                          }
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.city}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <Autocomplete
                                          options={states}
                                          getOptionLabel={(option) =>
                                            `${option.description} (${option.abbreviation})`
                                          }
                                          value={
                                            states.find(
                                              (state) => state.state_id === person.state_id
                                            ) ?? null
                                          }
                                          onChange={(e, value) => {
                                            handlePersonnelStateChange(
                                              brand.id,
                                              person.badge_id,
                                              value?.state_id ?? null,
                                              value?.abbreviation ?? null
                                            );
                                          }}
                                          onBlur={(e) =>
                                            updateBadgeError(
                                              person.badge_id,
                                              'state_id',
                                              e.target.value,
                                              true
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder='State'
                                              sx={{ minWidth: '150px' }}
                                              required
                                              error={!!badgeErrors[person.badge_id]?.state_id}
                                            />
                                          )}
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.state}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <TextField
                                          placeholder='Email Address'
                                          value={person.email_address ?? ''}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'email_address',
                                              e.target.value
                                            )
                                          }
                                          required
                                          error={!!badgeErrors[person.badge_id]?.email_address}
                                          onBlur={(e) =>
                                            updateBadgeEmailError(
                                              person.badge_id,
                                              e.target.value,
                                              true
                                            )
                                          }
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.email_address}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {editingRows.includes(person.badge_id) ? (
                                        <MaskedInput
                                          mask={[
                                            '(',
                                            /[1-9]/,
                                            /\d/,
                                            /\d/,
                                            ')',
                                            ' ',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                          ]}
                                          onChange={(e) =>
                                            handlePersonnelInputChange(
                                              brand.id,
                                              person.badge_id,
                                              'phone_number',
                                              e.target.value
                                            )
                                          }
                                          onBlur={(e) =>
                                            updateBadgePhoneError(
                                              brand.id,
                                              person.badge_id,
                                              e.target.value,
                                              false
                                            )
                                          }
                                          render={(ref, props) => (
                                            <TextField
                                              {...props}
                                              inputRef={ref}
                                              fullWidth
                                              placeholder='Cell Phone #'
                                              value={person.phone_number ?? ''}
                                              error={!!badgeErrors[person.badge_id]?.phone_number}
                                            />
                                          )}
                                        />
                                      ) : (
                                        <Typography variant='body1' textAlign='center'>
                                          {person.phone_number}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    {editingRows.includes(person.badge_id) && (
                                      <TableCell sx={{ padding: '16px', whiteSpace: 'pre' }}>
                                        <IconButton
                                          onClick={() => {
                                            if (validateBadgeData(person)) {
                                              handleSave(brand.id, person.badge_id);
                                            }
                                          }}
                                        >
                                          <Stack alignItems='center'>
                                            <Save />
                                            <Typography variant='caption'>SAVE</Typography>
                                          </Stack>
                                        </IconButton>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ))}
    </Paper>
  );
};

export default ERegistrationTableDesktop;

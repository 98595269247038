import { alpha } from '@mui/material/styles';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Cancel, ChangeCircle, CheckCircle } from '@mui/icons-material';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setExhibitorRegistration,
  clearExhibitorRegistration,
} from '../../../Redux/ExhibitorRegistrationSlice';
import { clearModalStatus, setModalStatus } from '../../../Redux/ModalSlice';
import { setRegistrationTable } from '../../../Redux/StateRefreshSlice';
import InvoiceEmail from './InvoiceEmail';
import { clearBlockNavigation, setBlockNavigation } from '../../../Redux/BlockNavigationSlice';
import ConfirmStartOverModal from './ConfirmStartOverModal';
import { isValidEmail } from '../../../_GlobalFunctions/InfoValidation';

const ConfirmModal = ({ userInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userAccessCode = useSelector((state) => state.userInformation.value.accessCode);
  const modalStatus = useSelector((state) => state.modalStatus.confirmModal);
  const isEditing = useSelector((state) => state.registration.isEditing);
  const selectedItems = useSelector((state) => state.registration.addons);
  const blockNavigation = useSelector((state) => state.blockNavigation);

  const boothNumber = useSelector((state) => state.registration.boothNumber);
  const boothType = useSelector((state) => state.registration.boothType);
  const boothPrice = useSelector((state) => state.registration.boothPrice);

  const exhibitorId = useSelector((state) => state.registration.exhibitorId);
  const companyName = useSelector((state) => state.registration.companyName);
  const companyEmail = useSelector((state) => state.registration.companyEmail);
  const companyPhone = useSelector((state) => state.registration.companyPhone);
  const companyWebsite = useSelector((state) => state.registration.companyWebsite);

  const paymentEmail = useSelector((state) => state.registration.paymentEmail);
  const paymentType = useSelector((state) => state.registration.paymentType);
  const paymentNotes = useSelector((state) => state.registration.paymentNotes);

  const [loading, setLoading] = useState(false);
  const [isConfirmStartOverModalOpen, setIsConfirmStartOverModalOpen] = useState(false);

  const groupedItems = useMemo(() => {
    const groups = {};
    selectedItems.forEach((item) => {
      if (!groups[item.addon_type_description]) {
        groups[item.addon_type_description] = [];
      }
      groups[item.addon_type_description].push(item);
    });
    return groups;
  }, [selectedItems]);

  const totalPrice = useMemo(() => {
    const itemsTotal = selectedItems.reduce((total, item) => total + item.price * item.quantity, 0);
    return itemsTotal + Number(boothPrice || 0);
  }, [selectedItems, boothPrice]);

  const formatPrice = (price) => {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
    const phoneNumberLength = phoneNumberDigits.length;
    if (phoneNumberLength < 4) return phoneNumberDigits;
    if (phoneNumberLength < 7) {
      return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(3)}`;
    }
    return `(${phoneNumberDigits.slice(0, 3)}) ${phoneNumberDigits.slice(
      3,
      6
    )}-${phoneNumberDigits.slice(6, 10)}`;
  };

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [emailTouched, setEmailTouched] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    axios
      .get('/rotr/paymentMethods')
      .then((res) => {
        setPaymentMethods(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const validateForm = useCallback(() => {
    if (paymentType && isValidEmail(paymentEmail)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [paymentType, paymentEmail]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const resetState = () => {
    dispatch(clearExhibitorRegistration());
    dispatch(clearModalStatus());
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const submitRegistration = async () => {
    setOpenSnackbar(false);
    setLoading(true);
    const blockNavigationHolder = {
      origin: blockNavigation.origin,
      when: blockNavigation.when,
      message: blockNavigation.message,
    };
    dispatch(clearBlockNavigation());
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      const sendToExpo = await axios.post('/rotr/addExhibitorAndReserveBooth', {
        exhibitorName: companyName,
        exhibitorEmail: companyEmail,
        exhibitorPhone: companyPhone,
        exhibitorWebsite: companyWebsite,
        boothName: boothNumber,
      });

      if (sendToExpo.data.message === 'Booth Unavailable') {
        setLoading(false);
        navigate('/e-register', { replace: true });
        dispatch(setModalStatus({ confirmModal: false }));
        setSnackbarMessage('The booth you selected is no longer available. Please try again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        resetState();
        return;
      }

      const expofpEditLink = sendToExpo.data.exhibitorDetails.autoLoginUrl;
      const expofpIdNumber = sendToExpo.data.exhibitorDetails.id;

      // Ensure each item has a valid id
      const addons = selectedItems.map((item) => ({
        rotr_exhibitor_addon_id: item.addon_id, // Use the correct field name here
        quantity: item.quantity,
      }));

      await axios.post('/rotr/addExhibitor', {
        addons, // Pass the structured addons array
        accessCode: userAccessCode,
        exhibitorName: companyName,
        exhibitorEmail: companyEmail,
        exhibitorPhone: companyPhone,
        exhibitorWebsite: companyWebsite,
        boothNumber: boothNumber,
        boothPrice: boothPrice,
        boothType: boothType,
        expofpEditLink: expofpEditLink,
        expofpIdNumber: expofpIdNumber,
        paymentType,
        paymentTotal: totalPrice,
        paymentEmail,
        paymentNotes,
      });

      InvoiceEmail(userInfo.email, {
        action: 'add',
        firstName: userInfo.firstName,
        booth: boothNumber,
        exhibitorName: companyName,
        accessCode: userAccessCode,
        link: 'https://www.rendezvousonthered.com/e-register',
      }).catch((err) => {
        console.error(err);
      });

      resetState();
      setLoading(false);
      setSnackbarMessage('Registration submitted successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      dispatch(setModalStatus({ confirmModal: false }));
      dispatch(setRegistrationTable(true));
      navigate('/e-register', { replace: true });
    } catch (error) {
      console.error(error);
      setSnackbarMessage(
        'There was an error submitting your registration. Please try again or contact support.'
      );
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
      dispatch(setBlockNavigation(blockNavigationHolder));
    }
  };

  const updateRegistration = async () => {
    setOpenSnackbar(false);
    setLoading(true);
    const blockNavigationHolder = {
      origin: blockNavigation.origin,
      when: blockNavigation.when,
      message: blockNavigation.message,
    };
    dispatch(clearBlockNavigation());
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      // Ensure each item has a valid id
      const addons = selectedItems.map((item) => ({
        rotr_exhibitor_addon_id: item.addon_id, // Use the correct field name here
        quantity: item.quantity,
      }));

      await axios.put('/rotr/exhibitors/updateRegistration', {
        exhibitorId: exhibitorId,
        addons,
        exhibitorName: companyName,
        exhibitorEmail: companyEmail,
        exhibitorPhone: companyPhone,
        exhibitorWebsite: companyWebsite,
        paymentType,
        paymentTotal: totalPrice,
        paymentEmail,
        paymentNotes,
      });

      InvoiceEmail(userInfo.email, {
        action: 'update',
        firstName: userInfo.firstName,
        booth: boothNumber,
        exhibitorName: companyName,
        accessCode: userAccessCode,
        link: 'https://www.rendezvousonthered.com/e-register',
      }).catch((err) => {
        console.error(err);
      });

      resetState();
      setLoading(false);
      setSnackbarMessage('Registration updated successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      dispatch(setModalStatus({ confirmModal: false }));
      dispatch(setRegistrationTable(true));
      navigate('/e-register', { replace: true });
    } catch (error) {
      console.error(error);
      setSnackbarMessage(
        'There was an error submitting your registration. Please try again or contact support.'
      );
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
      dispatch(setBlockNavigation(blockNavigationHolder));
    }
  };

  const handleStartOverCancel = () => {
    setIsConfirmStartOverModalOpen(false);
  };

  const handleStartOverConfirm = () => {
    setIsConfirmStartOverModalOpen(false);
    resetState();
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 13000 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Modal
        open={modalStatus}
        onClose={() => dispatch(setModalStatus({ confirmModal: false }))}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          animation: 'fadeIn 0.3s ease',
          '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
        }}
      >
        <Paper
          sx={{
            p: 4,
            maxWidth: 600,
            width: '100%',
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: 3,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f7f8fa',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              fontWeight: 'bold',
              marginBottom: 2,
              textAlign: 'center',
              color: '#00325a',
              fontSize: '1.75rem',
            }}
          >
            Confirm Registration
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />

          <Box
            sx={{
              py: 1.5,
              px: 2,
              backgroundColor: '#fff',
              borderRadius: 2,
              mb: 3,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#00325a', mb: 1 }}>
              Exhibitor Information
            </Typography>
            <List disablePadding>
              {companyName && (
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Company Name
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {companyName}
                    </Typography>
                  </Box>
                </ListItem>
              )}
              {companyPhone && (
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Phone Number
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {formatPhoneNumber(companyPhone)}
                    </Typography>
                  </Box>
                </ListItem>
              )}
              {companyEmail && (
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Email Address
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {companyEmail}
                    </Typography>
                  </Box>
                </ListItem>
              )}
              {companyWebsite && (
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Company Website
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      {companyWebsite}
                    </Typography>
                  </Box>
                </ListItem>
              )}
            </List>
          </Box>

          {boothNumber && (
            <Box
              sx={{
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 3,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', color: '#00325a', marginBottom: 1 }}
              >
                Booth Selection
              </Typography>
              <List disablePadding>
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Booth: {boothNumber}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      Type: {boothType}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                  >
                    {`$${formatPrice(Number(boothPrice))}`}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          )}

          {Object.keys(groupedItems).map((stepHeader) => (
            <Box
              key={stepHeader}
              sx={{
                py: 1.5,
                px: 2,
                backgroundColor: '#fff',
                borderRadius: 2,
                mb: 3,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', marginBottom: 1, color: '#00325a' }}
              >
                {stepHeader}
              </Typography>
              <List disablePadding>
                {groupedItems[stepHeader].map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      py: 1.5,
                      px: 2,
                      backgroundColor: '#fff',
                      borderRadius: 2,
                      mb: 1,
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Box>
                      <Typography
                        variant='body1'
                        sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                      >
                        {item.addon_description}
                      </Typography>
                      <Typography variant='body2' color='textSecondary'>
                        {`Price: $${formatPrice(item.price)}`}
                      </Typography>
                      <Typography variant='body2' color='textSecondary'>
                        {`Quantity: ${item.quantity}`}
                      </Typography>
                    </Box>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      {`$${formatPrice(item.price * item.quantity)}`}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}

          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              backgroundColor: '#fff',
              borderRadius: 2,
              mb: 3,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              mt: 0,
              ml: 0,
              width: '100%',
              pl: 0,
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 'bold', color: '#cb351a', textAlign: 'center' }}
              >
                Payment is not collected at this time. An invoice will be sent to the email
                provided.
              </Typography>
            </Grid>
            {isMobile && (
              <Grid item xs={12} md={6} sx={{ order: isMobile ? -1 : 'initial' }}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    backgroundColor: '#ffffff',
                    borderRadius: 2,
                    textAlign: isMobile ? 'center' : 'right',
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', color: '#00325a', mr: 1, display: 'inline-block' }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', color: '#ff5722', display: 'inline-block' }}
                  >
                    $
                    {Object.keys(selectedItems).length > 0 || boothNumber
                      ? formatPrice(totalPrice)
                      : '0'}
                  </Typography>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={paymentMethods}
                getOptionLabel={(option) => option.description}
                value={
                  paymentMethods.find((method) => method.payment_type_code === paymentType) || null
                }
                onChange={(e, value) =>
                  dispatch(
                    setExhibitorRegistration({ paymentType: value ? value.payment_type_code : '' })
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Payment Method'
                    variant='outlined'
                    required
                    fullWidth
                    sx={{
                      '& .MuiFormLabel-root': {
                        color: '#00325a',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      sx: { backgroundColor: 'white' },
                    }}
                  />
                )}
              />
            </Grid>
            {!isMobile && (
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{ p: 1.5, backgroundColor: '#ffffff', borderRadius: 2, textAlign: 'right' }}
                >
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', color: '#00325a', mr: 1, display: 'inline-block' }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', color: '#ff5722', display: 'inline-block' }}
                  >
                    $
                    {Object.keys(selectedItems).length > 0 || boothNumber
                      ? formatPrice(totalPrice)
                      : '0'}
                  </Typography>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Invoice Email'
                variant='outlined'
                required
                error={emailTouched && !isValidEmail(paymentEmail)}
                helperText={
                  emailTouched && !isValidEmail(paymentEmail)
                    ? 'Please enter a valid email address.'
                    : ''
                }
                value={paymentEmail}
                onChange={(e) =>
                  dispatch(setExhibitorRegistration({ paymentEmail: e.target.value }))
                }
                onBlur={() => setEmailTouched(true)}
                InputProps={{ sx: { backgroundColor: 'white' } }}
                sx={{
                  '& .MuiFormLabel-root': {
                    color: '#00325a',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Notes'
                variant='outlined'
                value={paymentNotes}
                onChange={(e) =>
                  dispatch(setExhibitorRegistration({ paymentNotes: e.target.value }))
                }
                InputProps={{ sx: { backgroundColor: 'white' } }}
                sx={{
                  '& .MuiFormLabel-root': {
                    color: '#00325a',
                  },
                }}
              />
            </Grid>

            <Divider sx={{ width: '100%', p: 1 }} />

            <Grid container item spacing={2}>
              <Grid item xs={12} md={4}>
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => setIsConfirmStartOverModalOpen(true)}
                  startIcon={<Cancel />}
                  sx={{
                    backgroundColor: '#cb351a',
                    '&:hover': {
                      backgroundColor: alpha('#cb351a', 0.8),
                    },
                    borderRadius: 2,
                    fontWeight: 'bold',
                    color: '#fff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    textTransform: 'none',
                    fontSize: '1rem',
                    py: 1,
                  }}
                >
                  Start Over
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => dispatch(setModalStatus({ confirmModal: false }))}
                  startIcon={<ChangeCircle />}
                  sx={{
                    backgroundColor: '#00325a',
                    '&:hover': {
                      backgroundColor: alpha('#00325a', 0.8),
                    },
                    borderRadius: 2,
                    fontWeight: 'bold',
                    color: '#fff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    textTransform: 'none',
                    fontSize: '1rem',
                    py: 1,
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant='contained'
                  fullWidth
                  startIcon={<CheckCircle />}
                  onClick={isEditing ? updateRegistration : submitRegistration}
                  sx={{
                    backgroundColor: '#66BB6A',
                    '&:hover': {
                      backgroundColor: alpha('#66BB6A', 0.8),
                    },
                    borderRadius: 2,
                    fontWeight: 'bold',
                    color: '#fff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    textTransform: 'none',
                    fontSize: '1rem',
                    py: 1,
                  }}
                  disabled={!isFormValid || loading}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <ConfirmStartOverModal
        open={isConfirmStartOverModalOpen}
        handleClose={handleStartOverCancel}
        handleDelete={handleStartOverConfirm}
        bigText='Are you sure you want to start over?'
        smallText='This will discard any recent changes you have made and return you to the registration page.'
      />
    </>
  );
};

export default ConfirmModal;

import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    rendezvousRed: {
      light: '#d55d47',
      main: '#cb351a',
      dark: '#8e2512',
      contrastText: '#fff',
    },
  },
});

export default Theme;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Grid, styled, CardMedia, CardContent, Button, Typography, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useSelector } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  width: '100%',
}));

const StyledCardMedia = styled(CardMedia)({
  height: 220,
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledBookButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#cb351a',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#cb351a',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.5,
}));

const StyledIcon = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: '#cb351a',
}));

const StyledPrice = styled('span')(({ theme }) => ({
  color: '#cb351a',
  marginLeft: theme.spacing(0.5),
}));

const Hotels = () => {
  const [content, setContent] = useState(null);
  const [hotels, setHotels] = useState(null);
  const userType = useSelector((state) => state.userInformation.value.userType);

  useEffect(() => {
    axios
      .get('/rotr/hotels/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (userType === 'G7h8I9j0K1l2') {
      axios
        .post(
          '/rotr/hotels/userType',
          {},
          {
            params: {
              userType: 'EXHIBITORS',
            },
          }
        )
        .then((res) => {
          setHotels(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (userType === 'M3n4O5p6Q7r8') {
      axios
        .post(
          '/rotr/hotels/userType',
          {},
          {
            params: {
              userType: 'ATTENDEES',
            },
          }
        )
        .then((res) => {
          setHotels(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (userType === 'A1b2C3d4E5f6') {
      axios
        .get('/rotr/hotels/')
        .then((res) => {
          setHotels(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setHotels([]);
    }
  }, [userType]);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{}}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} md={12} id='HOTELS_ROW1_COL1' />
        <Grid container item xs={12} justifyContent='center' spacing={2}>
          {hotels &&
            hotels.map((hotel) => (
              <Grid item xs={12} md={6} lg={3} key={hotel.rotr_hotel_id}>
                <Box display='flex' justifyContent='center' width='100%'>
                  <StyledCard>
                    <StyledCardMedia image={hotel.image_url} title={hotel.description} />
                    <StyledCardContent>
                      <StyledTypography variant='h6' sx={{ fontWeight: 'bold' }}>
                        <StyledIcon>
                          <LocationOnIcon />
                        </StyledIcon>
                        {hotel.description}
                      </StyledTypography>
                      <StyledTypography variant='subtitle1'>
                        <StyledIcon>
                          <AttachMoneyIcon />
                        </StyledIcon>
                        Starting at <StyledPrice>${hotel.starting_price}</StyledPrice>
                      </StyledTypography>
                    </StyledCardContent>
                    <Box display='flex' justifyContent='center' alignItems='center' pb={3}>
                      <StyledBookButton
                        variant='contained'
                        color='primary'
                        href={hotel.link_url}
                        target='_blank'
                        rel='noreferrer'
                        endIcon={<OpenInNewIcon />}
                      >
                        Book Now
                      </StyledBookButton>
                    </Box>
                  </StyledCard>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hotels;

import React from 'react';
import ReactDOMServer from 'react-dom/server';

const BadgeConfirmationEmailLayout = ({ details }) => {
  const {
    action,
    badgeFirstName,
    badgeLastName,
    badgeCompanyName,
    badgeJobTitle,
    badgeCity,
    badgeState,
    badgeEmailAddress,
    badgePhoneNumber,
    contactFirstName,
    contactLastName,
    contactCompanyName,
  } = details;

  const content = (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.5' }}>
      {action === 'add' ? (
        <p style={{ maxWidth: '500px', textAlign: 'justify' }}>
          Your registration for Rendezvous on the Red is confirmed!
          <br />
          <br />
          Show Badge Details:
          <br />
          <br />
          First Name: <strong>{badgeFirstName}</strong>
          <br />
          Last Name: <strong>{badgeLastName}</strong>
          <br />
          Company Name: <strong>{badgeCompanyName}</strong>
          <br />
          Job Title: <strong>{badgeJobTitle}</strong>
          <br />
          City: <strong>{badgeCity}</strong>
          <br />
          State: <strong>{badgeState}</strong>
          <br />
          <br />
          Your Contact Information:
          <br />
          <br />
          Email Address: <strong>{badgeEmailAddress}</strong>
          <br />
          Phone Number: <strong>{badgePhoneNumber}</strong>
          <br />
          <br />
          You were added as an attendee for the 2025 Sports South Rendezvous on the Red by{' '}
          <strong>{contactFirstName + ' ' + contactLastName}</strong>, with{' '}
          <strong>{contactCompanyName}</strong>. If you need to update your information, please
          reach out to <strong>{contactFirstName}</strong> directly.
        </p>
      ) : (
        <p style={{ maxWidth: '500px', textAlign: 'justify' }}>
          Your registration for Rendezvous on the Red has been updated!
          <br />
          <br />
          Show Badge Details:
          <br />
          <br />
          First Name: <strong>{badgeFirstName}</strong>
          <br />
          Last Name: <strong>{badgeLastName}</strong>
          <br />
          Company Name: <strong>{badgeCompanyName}</strong>
          <br />
          Job Title: <strong>{badgeJobTitle}</strong>
          <br />
          City: <strong>{badgeCity}</strong>
          <br />
          State: <strong>{badgeState}</strong>
          <br />
          <br />
          Your Contact Information:
          <br />
          <br />
          Email Address: <strong>{badgeEmailAddress}</strong>
          <br />
          Phone Number: <strong>{badgePhoneNumber}</strong>
          <br />
          <br />
          Your information as an attendee for the 2025 Sports South Rendezvous on the Red was
          updated by <strong>{contactFirstName + ' ' + contactLastName}</strong>, with{' '}
          <strong>{contactCompanyName}</strong>. If you need to further update your information,
          please reach out to <strong>{contactFirstName}</strong> directly.
        </p>
      )}
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(content);
};

export default BadgeConfirmationEmailLayout;

import React from 'react';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add,
  CheckCircle,
  Delete,
  Edit,
  ExpandMore,
  ExpandLess,
  Image,
  Save,
  Visibility,
  AccountBox,
} from '@mui/icons-material';
import MaskedInput from 'react-text-mask';
import { useSelector, useDispatch } from 'react-redux';
import { setModalStatus } from '../../../Redux/ModalSlice';

const ERegistrationTableMobile = ({
  expandedBrandIds,
  expandedPersonnelIds,
  badgeErrors,
  editingRows,
  handleExpandClick,
  handleViewRegistrationModal,
  handleBoothEdit,
  handleDeleteClick,
  handleAddPersonnel,
  handlePersonnelExpandClick,
  handlePersonnelInputChange,
  updateBadgeError,
  updateBadgeEmailError,
  updateBadgePhoneError,
  handleBadgeModal,
  validateBadgeData,
  handleSave,
  handleEdit,
  deletePersonnel,
  states,
  handlePersonnelStateChange,
}) => {
  const exhibitors = useSelector((state) => state.exhibitors.value);
  const highlightPersonnelAdd = useSelector((state) => state.modalStatus.exhibitorBadges);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            backgroundColor: '#f8f9fa',
            py: 2,
            '& .exhibitor-container:last-of-type': { mb: 0 },
          }}
        >
          {exhibitors.map((brand) => (
            <Box
              sx={{ border: '1px solid #ccc', borderRadius: '4px', mb: 2 }}
              key={brand.id}
              className='exhibitor-container'
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 2,
                  backgroundColor: alpha('#dbdad4', 0.5),
                }}
                onMouseUp={() => {
                  if (!window.getSelection().toString()) {
                    handleExpandClick(brand.id);
                  }
                }}
              >
                <Typography sx={{ opacity: 0 }}>
                  <ExpandMore />
                </Typography>
                <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
                  <Typography variant='h6' sx={{ fontWeight: 'bold', lineHeight: '1.5rem' }}>
                    {brand.exhibitor_name} (Booth: {brand.booth_number})
                  </Typography>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: 'green',
                      color: 'white',
                      borderRadius: 1,
                      px: 1,
                      mt: 1,
                    }}
                  >
                    <CheckCircle sx={{ fontSize: 'small', mr: 0.5 }} />
                    <Typography variant='body2'>Confirmed</Typography>
                  </Box>
                </Box>
                <IconButton>
                  {expandedBrandIds.includes(brand.id) ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={expandedBrandIds.includes(brand.id)} timeout='auto' unmountOnExit>
                <Box id={`table_${brand.id}`} sx={{ p: 2 }}>
                  <Typography variant='body1'>
                    <strong>Company Name:</strong>
                    <Box component='span' sx={{ float: 'right' }}>
                      {brand.exhibitor_name}
                    </Box>
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Company Phone #:</strong>
                    <Box component='span' sx={{ float: 'right' }}>
                      {brand.exhibitor_phone}
                    </Box>
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Company Email:</strong>
                    <Box component='span' sx={{ float: 'right' }}>
                      {brand.exhibitor_email}
                    </Box>
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Company Website:</strong>
                    <Box component='span' sx={{ float: 'right' }}>
                      {brand.exhibitor_website}
                    </Box>
                  </Typography>
                  <Stack direction='row' justifyContent='center' spacing={4} my={2}>
                    <IconButton
                      onClick={() => dispatch(setModalStatus({ profileExplanation: true }))}
                    >
                      <Stack alignItems='center'>
                        <AccountBox />
                        <Typography variant='caption'>PROFILE</Typography>
                      </Stack>
                    </IconButton>
                    <IconButton onClick={() => handleViewRegistrationModal(brand.id)}>
                      <Stack alignItems='center'>
                        <Visibility />
                        <Typography variant='caption'>VIEW</Typography>
                      </Stack>
                    </IconButton>
                    <IconButton
                      onClick={() => handleBoothEdit(brand.id)}
                      disabled={brand.allow_edit === 0}
                    >
                      <Stack alignItems='center'>
                        <Edit />
                        <Typography variant='caption'>EDIT</Typography>
                      </Stack>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(brand.id)}>
                      <Stack alignItems='center'>
                        <Delete />
                        <Typography variant='caption'>DELETE</Typography>
                      </Stack>
                    </IconButton>
                  </Stack>
                  <Box display='flex' flexDirection='column' alignItems='center' my={2}>
                    <Button
                      onClick={() => {
                        dispatch(setModalStatus({ exhibitorBadges: false }));
                        handleAddPersonnel(brand.id);
                      }}
                      startIcon={<Add />}
                      sx={{
                        '@keyframes blinkOutline': {
                          '0%': {
                            outline: '2px solid rgba(255, 0, 0, 0)',
                          },
                          '50%': {
                            outline: '2px solid rgba(255, 0, 0, 1)',
                          },
                          '100%': {
                            outline: '2px solid rgba(255, 0, 0, 0)',
                          },
                        },
                        animation: highlightPersonnelAdd ? 'blinkOutline 2s infinite' : 'none',
                      }}
                    >
                      Add Booth Personnel (badges)
                    </Button>
                  </Box>
                  {brand.personnel.map((person) => (
                    <Box
                      key={person.badge_id}
                      sx={{
                        mt: 2,
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 2,
                          backgroundColor: alpha('#dbdad4', 0.5),
                        }}
                        onMouseUp={() => {
                          if (!window.getSelection().toString()) {
                            handlePersonnelExpandClick(brand.id, person.badge_id);
                          }
                        }}
                      >
                        <Typography sx={{ opacity: 0 }}>
                          <ExpandMore />
                        </Typography>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                          {person.first_name} {person.last_name}
                        </Typography>
                        <IconButton>
                          {expandedPersonnelIds[brand.id] &&
                          expandedPersonnelIds[brand.id].includes(person.badge_id) ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse
                        in={
                          expandedPersonnelIds[brand.id] &&
                          expandedPersonnelIds[brand.id].includes(person.badge_id)
                        }
                        timeout='auto'
                        unmountOnExit
                      >
                        <Box sx={{ p: 2 }}>
                          {editingRows.includes(person.badge_id) ? (
                            <>
                              <TextField
                                label='First Name'
                                fullWidth
                                value={person.first_name ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'first_name',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={!!badgeErrors[person.badge_id]?.first_name}
                                onBlur={(e) =>
                                  updateBadgeError(
                                    person.badge_id,
                                    'first_name',
                                    e.target.value,
                                    true
                                  )
                                }
                              />
                              <TextField
                                label='Last Name'
                                fullWidth
                                value={person.last_name ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'last_name',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={!!badgeErrors[person.badge_id]?.last_name}
                                onBlur={(e) =>
                                  updateBadgeError(
                                    person.badge_id,
                                    'last_name',
                                    e.target.value,
                                    true
                                  )
                                }
                              />
                              <TextField
                                label='Job Title'
                                fullWidth
                                value={person.job_title ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'job_title',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                              />
                              <TextField
                                label='Company Name'
                                fullWidth
                                value={person.company_name ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'company_name',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={!!badgeErrors[person.badge_id]?.company_name}
                                onBlur={(e) =>
                                  updateBadgeError(
                                    person.badge_id,
                                    'company_name',
                                    e.target.value,
                                    true
                                  )
                                }
                              />
                              <TextField
                                placeholder='City'
                                fullWidth
                                value={person.city ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'city',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={!!badgeErrors[person.badge_id]?.city}
                                onBlur={(e) =>
                                  updateBadgeError(person.badge_id, 'city', e.target.value, true)
                                }
                              />
                              <Autocomplete
                                options={states}
                                getOptionLabel={(option) =>
                                  `${option.description} (${option.abbreviation})`
                                }
                                value={
                                  states.find((state) => state.state_id === person.state_id) ?? null
                                }
                                onChange={(e, value) => {
                                  handlePersonnelStateChange(
                                    brand.id,
                                    person.badge_id,
                                    value?.state_id ?? null,
                                    value?.abbreviation ?? null
                                  );
                                }}
                                onBlur={(e) =>
                                  updateBadgeError(
                                    person.badge_id,
                                    'state_id',
                                    e.target.value,
                                    true
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder='State'
                                    fullWidth
                                    margin='normal'
                                    required
                                    error={!!badgeErrors[person.badge_id]?.state_id}
                                  />
                                )}
                              />
                              <TextField
                                label='Email Address'
                                fullWidth
                                value={person.email_address ?? ''}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'email_address',
                                    e.target.value
                                  )
                                }
                                margin='normal'
                                required
                                error={!!badgeErrors[person.badge_id]?.email_address}
                                onBlur={(e) =>
                                  updateBadgeEmailError(person.badge_id, e.target.value, true)
                                }
                              />
                              <MaskedInput
                                mask={[
                                  '(',
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ')',
                                  ' ',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  '-',
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                onChange={(e) =>
                                  handlePersonnelInputChange(
                                    brand.id,
                                    person.badge_id,
                                    'phone_number',
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  updateBadgePhoneError(
                                    brand.id,
                                    person.badge_id,
                                    e.target.value,
                                    false
                                  )
                                }
                                render={(ref, props) => (
                                  <TextField
                                    {...props}
                                    inputRef={ref}
                                    fullWidth
                                    margin='normal'
                                    label='Cell Phone #'
                                    placeholder='(123) 456-7890'
                                    value={person.phone_number ?? ''}
                                    error={!!badgeErrors[person.badge_id]?.phone_number}
                                  />
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    First Name:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.first_name}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    Last Name:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.last_name}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    Job Title:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.job_title}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    Company Name:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.company_name}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    City:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.city}</Typography>
                              </Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Box display='flex' alignItems='center'>
                                  <Box
                                    sx={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: '#00325A',
                                      marginRight: 1,
                                    }}
                                  />
                                  <Typography variant='body1' fontWeight={600}>
                                    State:
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>{person.state}</Typography>
                              </Box>
                              <Typography variant='body1'>
                                <strong>Email Address:</strong>
                                <Box component='span' sx={{ float: 'right' }}>
                                  {person.email_address}
                                </Box>
                              </Typography>
                              <Typography variant='body1'>
                                <strong>Cell Phone #:</strong>
                                <Box component='span' sx={{ float: 'right' }}>
                                  {person.phone_number}
                                </Box>
                              </Typography>
                            </>
                          )}

                          <Stack direction='row' justifyContent='center' spacing={4} mt={2}>
                            <IconButton onClick={() => handleBadgeModal(person)}>
                              <Stack alignItems='center'>
                                <Image />
                                <Typography variant='caption'>PREVIEW</Typography>
                              </Stack>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (editingRows.includes(person.badge_id)) {
                                  if (validateBadgeData(person)) {
                                    handleSave(brand.id, person.badge_id);
                                  }
                                } else {
                                  handleEdit(person.badge_id);
                                }
                              }}
                            >
                              <Stack alignItems='center'>
                                {editingRows.includes(person.badge_id) ? <Save /> : <Edit />}
                                <Typography variant='caption'>
                                  {editingRows.includes(person.badge_id) ? 'SAVE' : 'EDIT'}
                                </Typography>
                              </Stack>
                            </IconButton>
                            <IconButton onClick={() => deletePersonnel(brand.id, person.badge_id)}>
                              <Stack alignItems='center'>
                                <Delete />
                                <Typography variant='caption'>DELETE</Typography>
                              </Stack>
                            </IconButton>
                          </Stack>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                  {brand.personnel.length > 0 && (
                    <Box mt={2} textAlign='center'>
                      <Typography color='error.main' mb={1}>
                        If you are adding multiple booths, only include yourself on one of the
                        booths.
                      </Typography>
                      <Typography mb={1}>
                        Required columns are marked with (*) when editing.
                      </Typography>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        whiteSpace='pre'
                      >
                        <Typography>Info marked with</Typography>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: '#00325A',
                            mx: 1,
                          }}
                        />
                        <Typography>will be printed on badge.</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}
        </Paper>
      </Grid>
    </>
  );
};

export default ERegistrationTableMobile;

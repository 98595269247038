import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Components/Navigation/Navbar';
import Home from './Components/Home';
import Sponsors from './Components/Sponsors/Sponsors';
import Attendees from './Components/Dashboards/AttendeeDashboard/Attendees';
import Exhibitors from './Components/Dashboards/ExhibitorDashboard/Exhibitors';
import Discover from './Components/Discover';
import Footer from './Components/Navigation/Footer';
import { Alert, Box, Typography } from '@mui/material';
import axios from 'axios';
import ARegistration from './Components/A-Registration/ARegistration';
import ERegistration from './Components/E-Registration/ERegistration';
import AccessModal from './Components/AccessModal';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, setUserInformation } from '../Redux/UserInformationSlice';
import Hotels from './Components/Travel/Hotels';
import Flights from './Components/Travel/Flights';
import ConfirmedExhibitors from './Components/Dashboards/ConfirmedExhibitors';
import ExhibitorResourceCenter from './Components/Dashboards/ExhibitorDashboard/ExhibitorResourceCenter';
import ExhibitorFAQ from './Components/Dashboards/ExhibitorDashboard/ExhibitorFAQ';
import AttendeeResourceCenter from './Components/Dashboards/AttendeeDashboard/AttendeeResourceCenter';
import AttendeeFAQ from './Components/Dashboards/AttendeeDashboard/AttendeeFAQ';
import ExhibitorHelp from './Components/Dashboards/ExhibitorDashboard/ExhibitorHelp';
import AttendeeHelp from './Components/Dashboards/AttendeeDashboard/AttendeeHelp';
import ExhibitorItinerary from './Components/Dashboards/ExhibitorDashboard/ExhibitorItinerary';
import AttendeeItinerary from './Components/Dashboards/AttendeeDashboard/AttendeeItinerary';
import TravelSurvey from './Components/Travel/TravelSurvey';
import MarketingOpportunities from './Components/MarketingOpportunities/MarketingOpportunities';

const RendezvousOnTheRed = () => {
  const [globalStyling, setGlobalStyling] = useState(null);
  const [cookie, setCookie] = useState(Cookies.get('access-code'));
  const userInformation = useSelector((state) => state.userInformation.value);
  const location = useLocation();
  const accessCode = useMemo(() => {
    // Get the current URL
    const currentUrl = location.pathname;

    // Extract new access code from the URL
    const urlParts = currentUrl.split('/');
    const newAccessCodeIndex = urlParts.indexOf('login') + 1;

    // Check if new access code exists and is in valid format
    const dominantAccessCode =
      newAccessCodeIndex < urlParts.length &&
      /^[A-Z0-9]{2}-[A-Z0-9]{2}-[A-Z0-9]{2}$/.test(urlParts[newAccessCodeIndex].toUpperCase())
        ? urlParts[newAccessCodeIndex].toUpperCase()
        : null;

    return dominantAccessCode ?? cookie ?? userInformation.accessCode;
  }, [cookie, userInformation.accessCode, location.pathname]);
  const dest = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('dest') || '/';
  }, [location.search]);
  const [isCodeValid, setIsCodeValid] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Rendezvous on the Red';
    axios
      .get('/rotr/style/content')
      .then((res) => {
        setGlobalStyling(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (globalStyling && globalStyling.length > 0) {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = globalStyling[0].content;

      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [globalStyling]);

  useEffect(() => {
    if (accessCode != null) {
      setIsCodeValid(null);
      axios
        .post('/rotr/checkAccessCode', {
          accessCode: accessCode,
        })
        .then((res) => {
          if (res.data.length > 0) {
            dispatch(
              setUserInformation({
                accessCode: accessCode,
                userType: res.data[0].user_type,
                hasCompletedSurvey: !!res.data[0].has_completed_survey,
              })
            );
            setIsCodeValid(true);
          } else {
            dispatch(logoutUser());
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(logoutUser());
        });
    } else {
      setIsCodeValid(false);
    }
  }, [accessCode, dispatch]);

  useEffect(() => {
    setCookie(Cookies.get('access-code'));
  }, [userInformation.accessCode]);

  if (isCodeValid === null) {
    return null;
  }

  return isCodeValid === false ? (
    <>
      <AccessModal open={true} handleClose={() => {}} />
    </>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Navbar />
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: '1600px',
            width: 'calc(100% - 32px)',
            m: '0 auto',
            px: '16px',
          }}
        >
          {showAlert &&
            location.pathname !== '/travel-survey' &&
            userInformation.hasCompletedSurvey === false && (
              <Alert
                severity='warning'
                onClose={() => {
                  setShowAlert(false);
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  my: 2,
                  '.MuiAlert-icon': {
                    ml: 'auto',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='body2' sx={{ display: 'inline', textAlign: 'center' }}>
                    Please take a moment to fill out our&nbsp;
                  </Typography>
                  <Typography
                    component='button'
                    variant='body2'
                    onClick={() => {
                      navigate('/travel-survey');
                    }}
                    sx={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      background: 'none',
                      border: 'none',
                      outline: 'none',
                      padding: 0,
                      font: 'inherit',
                      display: 'inline',
                      textAlign: 'center',
                    }}
                  >
                    travel survey.
                  </Typography>
                  <Typography variant='body2' sx={{ display: 'inline', textAlign: 'center' }}>
                    &nbsp;It takes less than a minute.
                  </Typography>
                </Box>
              </Alert>
            )}
          <Routes>
            <Route index element={<Home />} />
            {userInformation.userType !== 'G7h8I9j0K1l2' && (
              <>
                <Route path='/a-register' element={<ARegistration />} />
                <Route path='/attendees' element={<Attendees />} />
                <Route path='/attendee-faq' element={<AttendeeFAQ />} />
                <Route path='/attendee-help' element={<AttendeeHelp />} />
                <Route path='/attendee-itinerary' element={<AttendeeItinerary />} />
                <Route path='/attendee-resource-center' element={<AttendeeResourceCenter />} />
              </>
            )}
            {userInformation.userType !== 'M3n4O5p6Q7r8' && (
              <>
                <Route path='/e-register/*' element={<ERegistration />} />
                <Route path='/exhibitors' element={<Exhibitors />} />
                <Route path='/exhibitor-faq' element={<ExhibitorFAQ />} />
                <Route path='/exhibitor-help' element={<ExhibitorHelp />} />
                <Route path='/exhibitor-itinerary' element={<ExhibitorItinerary />} />
                <Route path='/exhibitor-resource-center' element={<ExhibitorResourceCenter />} />
                <Route path='/marketing-opportunities' element={<MarketingOpportunities />} />
              </>
            )}
            <Route path='/confirmed-exhibitors' element={<ConfirmedExhibitors />} />
            <Route path='/discover' element={<Discover />} />
            <Route path='/hotels' element={<Hotels />} />
            <Route path='/flights' element={<Flights />} />
            <Route path='/sponsors/*' element={<Sponsors />} />
            <Route path='/travel-survey' element={<TravelSurvey />} />
            <Route path='*' element={<Navigate to={dest} replace />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default RendezvousOnTheRed;

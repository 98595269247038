import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useState } from 'react';

const ConfirmModal = ({
  open,
  handleClose,
  handleConfirm,
  handleAfterClose,
  title = 'Confirm',
  contentText = 'Are you sure?',
}) => {
  const [loading, setLoading] = useState(false);

  const handleModalConfirm = () => {
    setLoading(true);
    handleConfirm(handleModalClose);
  };

  const handleModalClose = () => {
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          handleModalClose();
        }
      }}
      onTransitionExited={handleAfterClose}
      PaperProps={{
        sx: {
          px: 2,
          py: 1,
          '.MuiDialogActions-root': {
            justifyContent: 'center',
            gap: 1,
          },
        },
      }}
    >
      <DialogTitle textAlign='center'>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          textAlign='center'
          sx={{ color: 'rgba(0, 0, 0, 0.8)', overflowWrap: 'break-word' }}
        >
          {contentText}
        </DialogContentText>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleModalClose} color='error' disabled={loading}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={handleModalConfirm}
          color='success'
          autoFocus
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;

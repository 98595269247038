import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import StateRefreshSlice from './StateRefreshSlice';
import UserInformationSlice from './UserInformationSlice';
import ModalSlice from './ModalSlice';
import ExhibitorSlice from './ExhibitorSlice';
import AttendeeSlice from './AttendeeSlice';
import ExhibitorRegistrationSlice from './ExhibitorRegistrationSlice';
import AttendeeRegistrationSlice from './AttendeeRegistrationSlice';
import BlockNavigationSlice from './BlockNavigationSlice';

const reducer = combineReducers({
  // Add Reducers Here
  userInformation: UserInformationSlice,
  stateRefresh: StateRefreshSlice,
  modalStatus: ModalSlice,
  exhibitors: ExhibitorSlice,
  attendees: AttendeeSlice,
  registration: ExhibitorRegistrationSlice,
  attendeeRegistration: AttendeeRegistrationSlice,
  blockNavigation: BlockNavigationSlice,
});

const persistConfig = {
  key: 'whitelist',
  storage,
  whitelist: ['userInformation'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

import React, { useEffect, useState } from 'react';
import {
  Alert,
  alpha,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAttendeeRegistration } from '../../../Redux/AttendeeRegistrationSlice';
import axios from 'axios';
import { setRegistrationTable } from '../../../Redux/StateRefreshSlice';
import { clearBlockNavigation, setBlockNavigation } from '../../../Redux/BlockNavigationSlice';
import AttendeeConfirmationEmail from './AttendeeConfirmationEmail';
import MaskedInput from 'react-text-mask';
import { isValidEmail, isValidPhone, trimIfString } from '../../../_GlobalFunctions/InfoValidation';

const RegistrationForm = () => {
  const [showErrors, setShowErrors] = useState({
    companyName: false,
    email: false,
    phone: false,
  });
  const dispatch = useDispatch();
  const attendeeRegistration = useSelector((state) => state.attendeeRegistration);
  const accessCode = useSelector((state) => state.userInformation.value.accessCode);
  const blockNavigation = useSelector((state) => state.blockNavigation);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    axios
      .post(
        '/rotr/attendees/prefill',
        {},
        {
          params: { accessCode: accessCode },
        }
      )
      .then((res) => {
        dispatch(
          setAttendeeRegistration({
            attendeeName: res.data[0].company_name,
            attendeeEmail: res.data[0].email_address,
            attendeePhone: res.data[0].phone_number,
            accountNumber: res.data[0].account_number,
          })
        );
      });
  }, [dispatch, accessCode]);

  const handleNameChange = (e) => {
    dispatch(setAttendeeRegistration({ attendeeName: e.target.value }));
    setShowErrors({ ...showErrors, companyName: false });
  };

  const handleEmailChange = (e) => {
    dispatch(setAttendeeRegistration({ attendeeEmail: e.target.value }));
    setShowErrors({ ...showErrors, email: false });
  };

  const handlePhoneNumberChange = (e) => {
    dispatch(setAttendeeRegistration({ attendeePhone: e.target.value }));
    setShowErrors({ ...showErrors, phone: false });
  };

  const handleNameBlur = (e) => {
    const trimmedValue = trimIfString(e.target.value);
    setShowErrors({ ...showErrors, companyName: !trimmedValue });
  };

  const handleEmailBlur = (e) => {
    const trimmedValue = trimIfString(e.target.value);
    setShowErrors({ ...showErrors, email: !isValidEmail(trimmedValue) });
  };

  const handlePhoneBlur = (e) => {
    const trimmedValue = trimIfString(e.target.value);
    if (trimmedValue === '(___) ___-____') {
      dispatch(setAttendeeRegistration({ attendeePhone: '' }));
      setShowErrors({ ...showErrors, phone: true });
    } else {
      setShowErrors({ ...showErrors, phone: !isValidPhone(trimmedValue) });
    }
  };

  const submitRegistration = () => {
    setOpenSnackbar(false);
    setLoading(true);
    const blockNavigationHolder = {
      origin: blockNavigation.origin,
      when: blockNavigation.when,
      message: blockNavigation.message,
    };
    dispatch(clearBlockNavigation());
    const trimmedName = trimIfString(attendeeRegistration.attendeeName);
    const trimmedEmail = trimIfString(attendeeRegistration.attendeeEmail);
    axios
      .post('/rotr/attendees/register', {
        accessCode: accessCode,
        attendeeName: trimmedName,
        attendeeEmail: trimmedEmail,
        attendeePhone: attendeeRegistration.attendeePhone,
      })
      .then((res) => {
        AttendeeConfirmationEmail(trimmedEmail, {
          action: 'add',
          companyName: trimmedName,
          accessCode: accessCode,
          link: 'https://www.rendezvousonthered.com/attendees',
        }).catch((err) => {
          console.error(err);
        });

        setTimeout(() => {
          setLoading(false);
          setSnackbarMessage('Registration submitted successfully!');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          dispatch(setAttendeeRegistration({ isRegistered: true }));
          dispatch(setRegistrationTable(true));
        }, 250);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
          setSnackbarMessage(
            'There was an error submitting your registration. Please try again or contact support.'
          );
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
          dispatch(setBlockNavigation(blockNavigationHolder));
        }, 250);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const validateErrors = () => {
    const trimmedName = trimIfString(attendeeRegistration.attendeeName);
    const trimmedEmail = trimIfString(attendeeRegistration.attendeeEmail);
    const newShowErrors = {
      ...showErrors,
      companyName: !trimmedName,
      email: !isValidEmail(trimmedEmail),
      phone: !isValidPhone(attendeeRegistration.attendeePhone),
    };
    setShowErrors(newShowErrors);
    return Object.values(newShowErrors).some((error) => error);
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 13000 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label='Account Number'
            value={attendeeRegistration.accountNumber ?? ''}
            disabled
            InputLabelProps={{ shrink: true }}
            helperText='If you need to change your account number, please contact your sales representative.'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label='Company Name'
            value={attendeeRegistration.attendeeName ?? ''}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            error={showErrors.companyName}
            helperText={showErrors.companyName ? 'Company name is required.' : ' '}
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label='Email'
            value={attendeeRegistration.attendeeEmail ?? ''}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            error={showErrors.email}
            helperText={showErrors.email ? 'Please enter a valid email address.' : ' '}
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MaskedInput
            mask={[
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneBlur}
            render={(ref, props) => (
              <TextField
                {...props}
                inputRef={ref}
                fullWidth
                label='Phone Number'
                placeholder='(123) 456-7890'
                value={attendeeRegistration.attendeePhone ?? ''}
                error={showErrors.phone}
                helperText={showErrors.phone ? 'Please enter a valid phone number.' : ' '}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant='contained'
            onClick={() => {
              if (!validateErrors()) {
                submitRegistration();
              }
            }}
            sx={{
              backgroundColor: '#00325a',
              '&:hover': { backgroundColor: alpha('#00325a', 0.6) },
            }}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegistrationForm;

import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { updateUserInformation } from '../../../Redux/UserInformationSlice';

const TravelSurvey = () => {
  const [content, setContent] = useState(null);
  const [isFlying, setIsFlying] = useState(null);
  const userInformation = useSelector((state) => state.userInformation.value);
  const [zipCode, setZipCode] = useState('');
  const [airports, setAirports] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [flightDate, setFlightDate] = useState(null);
  const [airlines, setAirlines] = useState([]);
  const [selectedAirline, setSelectedAirline] = useState(null);
  const [airlineInputState, setAirlineInputState] = useState('dropdownFadeIn');
  const [numSeats, setNumSeats] = useState('1');
  const [loading, setLoading] = useState(false);
  const [preventFade, setPreventFade] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`/rotr/survey/content`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get('/rotr/airlines')
      .then((res) => {
        setAirlines(res.data);
      })
      .catch((error) => {
        console.error('Error fetching airlines:', error);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  const handleZipCodeChange = (event) => {
    let inputValue = event.target.value;

    // Remove any non-digit characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Limit to 5 digits
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5);
    }

    setZipCode(inputValue);
  };

  const handleFindAirports = () => {
    setPreventFade(true);
    setAirports([]);
    setLoading(true);
    axios
      .get('/rotr/airports', {
        params: { zipCode: zipCode },
      })
      .then((res) => {
        setAirports(res.data);
        if (!res.data.includes(selectedAirport)) {
          setSelectedAirport(null);
        }
      })
      .catch((error) => {
        setAirports('error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setOpenSnackbar(false);
    setLoading(true);
    axios
      .post('/rotr/survey', {
        accessCode: userInformation.accessCode,
        isFlying: !!isFlying,
        airlineName: isFlying ? selectedAirline : null,
        airportName: isFlying ? selectedAirport : null,
        seatsOccupied: isFlying ? numSeats : null,
        departureTime: isFlying ? dayjs(flightDate).format('YYYY-MM-DD') : null,
      })
      .then((res) => {
        dispatch(updateUserInformation({ hasCompletedSurvey: true }));
        setTimeout(() => {
          setSnackbarSeverity('success');
          setSnackbarMessage('Your survey was successfully submitted!');
          setOpenSnackbar(true);
        }, 250);
      })
      .catch((error) => {
        setTimeout(() => {
          setSnackbarSeverity('error');
          setSnackbarMessage('There was an issue with submitting the survey. Please try again.');
          setOpenSnackbar(true);
        }, 250);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const seatOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 15; i++) {
      options.push(String(i));
    }
    return options;
  }, []);

  const handleAirlineInputChange = () => {
    setPreventFade(false);
    if (airlineInputState === 'dropdownFadeIn') {
      setAirlineInputState('dropdownFadeOut');
    } else if (airlineInputState === 'inputFadeIn') {
      setAirlineInputState('inputFadeOut');
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Grid container justifyContent='center' spacing={5}>
        <Grid item container xs={12} id='SURVEY_ROW1_COL1' />
        <Grid item container xs={12} mb={8}>
          {!userInformation.hasCompletedSurvey ? (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Typography
                variant='h6'
                component='div'
                align='center'
                sx={{
                  fontStyle: 'italic',
                  color: '#222',
                  mb: '24px',
                }}
              >
                Thank you for registering! Please take a moment to complete the short survey below.
              </Typography>
              <FormControl sx={{ mb: 3 }}>
                <FormLabel id='travel-method-label' focused={false} sx={{ p: 2, color: '#222' }}>
                  Will you be driving or flying?
                </FormLabel>
                <RadioGroup
                  aria-labelledby='travel-method-label'
                  name='travel-method-group'
                  value={isFlying}
                  onChange={(e) => setIsFlying(e.target.value === 'true')}
                >
                  <FormControlLabel value={false} control={<Radio />} label='Driving' />
                  <FormControlLabel value={true} control={<Radio />} label='Flying' />
                </RadioGroup>
              </FormControl>
              {isFlying === true && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '12px',
                      mb: 2,
                    }}
                  >
                    <TextField
                      label='Zip Code'
                      value={zipCode}
                      onChange={handleZipCodeChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && zipCode.length >= 5 && !loading) {
                          handleFindAirports();
                        }
                      }}
                      sx={{ width: '100%', maxWidth: '200px' }}
                    />
                    <Button
                      variant='contained'
                      color='rendezvousRed'
                      onClick={handleFindAirports}
                      disabled={zipCode.length < 5 || loading}
                      startIcon={<Search />}
                      sx={{ whiteSpace: 'pre' }}
                    >
                      Find Airports
                    </Button>
                  </Box>
                  {loading ? (
                    <CircularProgress sx={{ mt: 8 }} />
                  ) : airports === 'error' ? (
                    <Typography
                      variant='h6'
                      component='div'
                      align='center'
                      sx={{
                        fontStyle: 'italic',
                        color: '#222',
                        mt: 8,
                      }}
                    >
                      No airports were found near this zip code.
                    </Typography>
                  ) : (
                    airports.length > 0 && (
                      <Paper sx={{ p: 2, width: '100%', maxWidth: '900px' }}>
                        <Grid container justifyContent='center' alignItems='center' spacing={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              options={airports}
                              getOptionLabel={(option) => option}
                              value={selectedAirport}
                              onChange={(event, newValue) => {
                                setSelectedAirport(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label='Select Starting Airport' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              label='Flight Date'
                              value={flightDate}
                              onChange={(newValue) => {
                                setFlightDate(newValue);
                              }}
                              referenceDate={dayjs('2025-06-15')}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                              sx={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              options={seatOptions}
                              getOptionLabel={(option) => option}
                              value={numSeats}
                              onChange={(event, newValue) => {
                                setNumSeats(newValue);
                              }}
                              disableClearable
                              renderInput={(params) => (
                                <TextField {...params} label='Select # of Seats' />
                              )}
                            />
                          </Grid>
                          {airlineInputState.startsWith('dropdown') && (
                            <Grid item xs={12}>
                              <Fade
                                in={airlineInputState === 'dropdownFadeIn'}
                                timeout={preventFade ? 0 : 250}
                                onExited={() => {
                                  if (airlineInputState === 'dropdownFadeOut') {
                                    setSelectedAirline(null);
                                    setAirlineInputState('inputFadeIn');
                                  }
                                }}
                              >
                                <Autocomplete
                                  options={airlines}
                                  getOptionLabel={(option) => option}
                                  value={selectedAirline}
                                  onChange={(event, newValue) => {
                                    setSelectedAirline(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label='Select Airline' />
                                  )}
                                />
                              </Fade>
                            </Grid>
                          )}
                          {airlineInputState.startsWith('input') && (
                            <Grid item xs={12}>
                              <Fade
                                in={airlineInputState === 'inputFadeIn'}
                                timeout={preventFade ? 0 : 250}
                                onExited={() => {
                                  if (airlineInputState === 'inputFadeOut') {
                                    setSelectedAirline(null);
                                    setAirlineInputState('dropdownFadeIn');
                                  }
                                }}
                              >
                                <TextField
                                  label='My Airline'
                                  placeholder='Please type the name of your airline here'
                                  value={selectedAirline ?? ''}
                                  onChange={(event) => setSelectedAirline(event.target.value)}
                                  fullWidth
                                  autoFocus
                                />
                              </Fade>
                            </Grid>
                          )}
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Fade
                              in={
                                airlineInputState === 'dropdownFadeIn' ||
                                airlineInputState === 'inputFadeIn'
                              }
                              timeout={preventFade ? 0 : 250}
                            >
                              <Typography
                                variant='body1'
                                color='primary'
                                align='center'
                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={handleAirlineInputChange}
                              >
                                {airlineInputState.startsWith('input')
                                  ? 'Click here to select a prefilled airline'
                                  : 'Click here if your airline is not listed above'}
                              </Typography>
                            </Fade>
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              variant='contained'
                              color='rendezvousRed'
                              onClick={handleSubmit}
                              disabled={
                                !selectedAirport ||
                                !flightDate?.isValid() ||
                                !numSeats ||
                                !selectedAirline ||
                                airlineInputState.includes('FadeOut')
                              }
                              sx={{ width: '100%', maxWidth: '125px', mt: 3 }}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    )
                  )}
                </>
              )}
              {isFlying === false && (
                <Button
                  variant='contained'
                  color='rendezvousRed'
                  onClick={handleSubmit}
                  sx={{ width: '100%', maxWidth: '125px', mt: 1 }}
                >
                  Submit
                </Button>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                variant='h6'
                component='div'
                align='center'
                sx={{
                  fontStyle: 'italic',
                  color: '#222',
                }}
              >
                Thank you for taking the time to complete this survey.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TravelSurvey;

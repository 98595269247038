import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  Grid,
  styled,
  CardMedia,
  CardContent,
  Button,
  Typography,
  Box,
  Link,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import PhoneIcon from '@mui/icons-material/Phone';
import DirectionsIcon from '@mui/icons-material/Directions';
import CommuteIcon from '@mui/icons-material/Commute';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'elevated' })(
  ({ theme, elevated }) => ({
    maxWidth: 400,
    borderRadius: theme.spacing(2),
    boxShadow: elevated ? theme.shadows[8] : theme.shadows[3],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);

const StyledCardMedia = styled(CardMedia)({
  height: 220,
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
}));

const StyledBookButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#cb351a',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#cb351a',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.5,
}));

const StyledIcon = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: '#cb351a',
}));

const Flights = () => {
  const [flights, setFlights] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get('/rotr/flights')
      .then((res) => {
        setFlights(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/rotr/flights/content`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{}}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} md={12} id='FLIGHTS_ROW1_COL1' />
        <Grid container item xs={12} justifyContent='center' spacing={2}>
          <Grid item container xs={12} lg={6}>
            <img
              src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/airports/a-map.png'
              alt='Flights'
              style={{ width: '100%', objectFit: 'contain' }}
            />
          </Grid>
          {flights &&
            flights.map((flight) => (
              <Grid item xs={12} md={6} lg={3} key={flight.rotr_poi_id} display='flex'>
                <Box display='flex' justifyContent='center' width='100%' flexGrow={1}>
                  <StyledCard
                    elevated={flight.rotr_poi_id === 15}
                    sx={
                      flight.rotr_poi_id === 15
                        ? {
                            outline: '3px solid #cb351a',
                          }
                        : {}
                    }
                  >
                    <StyledCardMedia image={flight.image_url} title={flight.description} />
                    <StyledCardContent>
                      <StyledTypography variant='h6' sx={{ fontWeight: 'bold' }}>
                        <StyledIcon>
                          <LocationOnIcon />
                        </StyledIcon>
                        {flight.description}
                      </StyledTypography>
                      <StyledTypography variant='subtitle1'>
                        <StyledIcon>
                          <PublicIcon />
                        </StyledIcon>
                        {flight.city}, {flight.state_description}
                      </StyledTypography>
                      <StyledTypography variant='subtitle1'>
                        <StyledIcon>
                          <DirectionsIcon />
                        </StyledIcon>
                        {flight.distance} Miles from Venue
                      </StyledTypography>
                      <StyledTypography variant='subtitle1'>
                        <StyledIcon>
                          <PhoneIcon />
                        </StyledIcon>
                        {flight.phone_number}
                      </StyledTypography>
                      <StyledTypography variant='subtitle1'>
                        <StyledIcon>
                          <CommuteIcon />
                        </StyledIcon>
                        <Link
                          href={flight.secondary_link_url}
                          target='_blank'
                          rel='noreferrer'
                          color='inherit'
                        >
                          Ground Transportation
                        </Link>
                      </StyledTypography>
                    </StyledCardContent>
                    <Box display='flex' justifyContent='center' alignItems='center' pb={3}>
                      <StyledBookButton
                        variant='contained'
                        color='primary'
                        href={flight.link_url}
                        target='_blank'
                        rel='noreferrer'
                        endIcon={<OpenInNewIcon />}
                      >
                        Visit Website
                      </StyledBookButton>
                    </Box>
                  </StyledCard>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Flights;

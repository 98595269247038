import axios from 'axios';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import BadgeConfirmationEmailLayout from './BadgeConfirmationEmailLayout';

const BadgeConfirmationEmail = (emailAddress, details) => {
  const htmlEncodedString = ReactDOMServer.renderToStaticMarkup(
    <BadgeConfirmationEmailLayout details={details} />
  );
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlEncodedString, 'text/html');
  const htmlContent = doc.body.textContent;

  const formData = new FormData();
  formData.append('emailAddress', emailAddress);
  formData.append(
    'subject',
    `Rendezvous on the Red Attendee ${details.action === 'add' ? 'Confirmation' : 'Update'}: ${
      details.badgeFirstName
    } ${details.badgeLastName}`
  );
  formData.append('htmlContent', htmlContent);

  return new Promise((resolve, reject) => {
    axios
      .post('/rotr/confirmationEmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default BadgeConfirmationEmail;

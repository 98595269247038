import React from 'react';
import ReactDOMServer from 'react-dom/server';

const AttendeeConfirmationEmailLayout = ({ details }) => {
  const { action, companyName, accessCode, link } = details;

  const content = (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.5' }}>
      <p>{companyName},</p>
      {action === 'add' ? (
        <p style={{ maxWidth: '500px', textAlign: 'justify' }}>
          Thank you for registering for Rendezvous on the Red! Your spot is confirmed. To manage
          your attendees, or to learn more about the show, please use the link below.
        </p>
      ) : (
        <p style={{ maxWidth: '500px', textAlign: 'justify' }}>
          Thank you for updating your registration for Rendezvous on the Red! Your information was
          successfully modified. To manage your attendees, or to learn more about the show, please
          use the link below.
        </p>
      )}
      <p>
        For convenience, your Invitation Code is: <strong>{accessCode}</strong>
      </p>
      <a
        href={link}
        style={{
          display: 'inline-block',
          padding: '10px 20px',
          margin: '20px 0',
          fontSize: '16px',
          color: '#fff',
          backgroundColor: '#00325a',
          textDecoration: 'none',
          borderRadius: '5px',
          textAlign: 'center',
        }}
      >
        Manage Attendees
      </a>
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(content);
};

export default AttendeeConfirmationEmailLayout;

import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import Ads from '../../Ads';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  boxShadow: theme.shadows[1],
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  border: 'solid 2px #00325a',
  color: '#00325a',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#00325a',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledButtonBase = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#00325a',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#00325a',
  },
}));

const ExhibitorHelp = () => {
  const [content, setContent] = useState(null);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    axios
      .get(`/rotr/exhibitorHelp/content`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/rotr/exhibitorHelp/questions`)
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Box sx={{}}>
      <Grid container>
        <Grid item container xs={12} id='EXHIBITOR_HELP_ROW1_COL1' />
        <Grid item container xs={12} columnSpacing={2} alignItems='flex-start'>
          <Grid item xs={12} md={9}>
            {questions.map((question) => (
              <StyledAccordion key={question}>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6'>{question.description}</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Typography>{question.detailed_description}</Typography>
                  {question.button_url && question.button_text && (
                    <StyledButtonBase variant='contained' component='a' href={question.button_url}>
                      {question.button_text}
                    </StyledButtonBase>
                  )}
                </StyledAccordionDetails>
              </StyledAccordion>
            ))}
          </Grid>
          <Grid item container xs={12} md={3} spacing={4}>
            <Ads numberToShow={6} gridXS={12} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExhibitorHelp;

import { Alert, Box, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setExhibitorRegistration } from '../../../Redux/ExhibitorRegistrationSlice';
import { isValidEmail, isValidPhone, trimIfString } from '../../../_GlobalFunctions/InfoValidation';
import MaskedInput from 'react-text-mask';

const ExhibitorInfo = () => {
  const registration = useSelector((state) => state.registration);
  const dispatch = useDispatch();

  const handleChange = (e, fieldName, errorName = '') => {
    dispatch(
      setExhibitorRegistration({
        [fieldName]: e.target.value,
        ...(errorName && registration.hasOwnProperty(errorName) && { [errorName]: false }),
      })
    );
  };

  const handleEmailChange = (e) => {
    dispatch(setExhibitorRegistration({ companyEmail: e.target.value, companyEmailError: false }));
  };

  const handlePhoneChange = (e) => {
    dispatch(setExhibitorRegistration({ companyPhone: e.target.value, companyPhoneError: false }));
  };

  const handleBlur = (e, errorName) => {
    if (registration.hasOwnProperty(errorName)) {
      const trimmedValue = trimIfString(e.target.value);
      dispatch(setExhibitorRegistration({ [errorName]: !trimmedValue }));
    }
  };

  const handleEmailBlur = (e, required = false) => {
    const trimmedValue = trimIfString(e.target.value);
    dispatch(
      setExhibitorRegistration({
        companyEmailError: required
          ? !isValidEmail(trimmedValue)
          : !!trimmedValue && !isValidEmail(trimmedValue),
      })
    );
  };

  const handlePhoneBlur = (e, required = false) => {
    const trimmedValue = trimIfString(e.target.value);
    if (trimmedValue === '(___) ___-____') {
      dispatch(setExhibitorRegistration({ companyPhone: '', companyPhoneError: required }));
    } else {
      dispatch(
        setExhibitorRegistration({
          companyPhoneError: required
            ? !isValidPhone(trimmedValue)
            : !!trimmedValue && !isValidPhone(trimmedValue),
        })
      );
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  return (
    <Grid container spacing={2}>
      {registration.boothNumber && (
        <Grid container item xs={12} md={12} justifyContent='center'>
          <Grid item xs={12} md={6}>
            <Box sx={{ marginBottom: 3 }}>
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', color: '#00325a', marginBottom: 1 }}
              >
                Booth Selection
              </Typography>
              <List disablePadding>
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 1.5,
                    px: 2,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                    >
                      Booth: {registration.boothNumber}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'>
                      Type: {registration.boothType}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    sx={{ fontWeight: 'bold', color: '#00325a', fontSize: '1.1rem' }}
                  >
                    {`$${formatPrice(Number(registration.boothPrice))}`}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Exhibitor Company Name'
          value={registration.companyName}
          onChange={(e) => handleChange(e, 'companyName', 'companyNameError')}
          onBlur={(e) => handleBlur(e, 'companyNameError')}
          error={registration.companyNameError}
          helperText={registration.companyNameError ? 'Company name is required.' : ' '}
          required
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Company Email'
          value={registration.companyEmail}
          onChange={handleEmailChange}
          onBlur={(e) => handleEmailBlur(e, false)}
          error={registration.companyEmailError}
          helperText={
            registration.companyEmailError ? 'Provided email address must be valid.' : ' '
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MaskedInput
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          onChange={handlePhoneChange}
          onBlur={(e) => handlePhoneBlur(e, false)}
          render={(ref, props) => (
            <TextField
              {...props}
              inputRef={ref}
              fullWidth
              label='Company Phone Number'
              value={registration.companyPhone}
              error={registration.companyPhoneError}
              helperText={
                registration.companyPhoneError ? 'Provided phone number must be valid.' : ' '
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label='Company Website'
          value={registration.companyWebsite}
          onChange={(e) => handleChange(e, 'companyWebsite')}
          helperText=' '
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity='info' sx={{ justifyContent: 'center' }}>
          This information will be publicly visible.
        </Alert>
      </Grid>
    </Grid>
  );
};

export default ExhibitorInfo;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import { Card, styled, CardMedia, CardContent, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  width: '100%',
}));

const StyledCardMedia = styled(CardMedia)({
  height: 220,
  maxHeight: 150,
  backgroundSize: 'contain',
  margin: '16px',
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#cb351a',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#cb351a',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.5,
  justifyContent: 'center',
}));

const DefaultSponsors = () => {
  const [content, setContent] = useState(null);
  const [defaultSponsors, setDefaultSponsors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('/rotr/sponsors/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get('/rotr/sponsors/default')
      .then((res) => {
        setDefaultSponsors(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item container xs={12} md={12} id='SPONSORS_ROW1_COL1' />
      {defaultSponsors.map((sponsor) => (
        <Grid item xs={12} md={3} key={sponsor.link_name}>
          <StyledCard>
            <StyledCardMedia image={sponsor.logo_url} title={sponsor.description} />
            <StyledCardContent>
              <StyledTypography variant='h6' sx={{ fontWeight: 'bold' }}>
                {sponsor.description}
              </StyledTypography>
              <Typography variant='body2' align='center' height={100}>
                {sponsor.short_description}
              </Typography>
              <Box display='flex' justifyContent='center'>
                <StyledButton
                  variant='contained'
                  onClick={() => navigate(`/sponsors/${sponsor.link_name}`)}
                >
                  Learn More
                </StyledButton>
              </Box>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default DefaultSponsors;

import { Box, IconButton } from '@mui/material';
import { Upload } from '@mui/icons-material';

const FileUploadButton = ({ onFileSelect }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (e.g., 50MB max)
      if (file.size > 50 * 1024 * 1024) {
        // 50MB in bytes
        alert('File size exceeds 50MB limit.');
        return;
      }
      onFileSelect(file, file.name);
      // Clear input after file select to allow selecting the same file again
      event.target.value = null;
    }
  };

  return (
    <Box>
      <IconButton component='label'>
        <Upload />
        <input type='file' hidden onChange={handleFileChange} accept='*' multiple={false} />
      </IconButton>
    </Box>
  );
};

export default FileUploadButton;

import './App.css';
import axios from 'axios';
import RendezvousOnTheRed from './RendezvousOnTheRed/RendezvousOnTheRed';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://ninja.sportssouth.dev';
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RendezvousOnTheRed />
    </LocalizationProvider>
  );
}

export default App;

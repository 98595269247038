import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const AttendeeItinerary = () => {
  const [content, setContent] = useState(null);
  const [entries, setEntries] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    axios
      .get('/rotr/attendeeItinerary/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get('/rotr/attendeeItinerary/entries')
      .then((res) => {
        const groupedEntries = res.data.reduce((acc, entry) => {
          acc[entry.day] = acc[entry.day] || [];
          acc[entry.day].push(entry);
          return acc;
        }, {});
        setEntries(groupedEntries);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content]);

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item container xs={12} md={12} id='ATTENDEE_ITINERARY_ROW1_COL1' />

      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems='flex-start'
        sx={
          isMobile
            ? {
                backgroundImage:
                  'url("https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/it_bg.jpg")',
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backgroundBlendMode: 'lighten',
              }
            : {}
        }
      >
        <Grid item container xs={12} md={8} lg={9} spacing={2}>
          {Object.entries(entries).map(([day, dayEntries], index) => (
            <Grid item container xs={12} spacing={2} key={day}>
              <Grid item xs={12}>
                <Typography variant='h5' gutterBottom sx={{ color: '#cb351a' }}>
                  {day}
                </Typography>
              </Grid>
              {dayEntries.map((entry) => (
                <Grid item xs={12} sm={6} lg={4} key={entry.time}>
                  <Card sx={{ borderLeft: '4px solid #cb351a' }}>
                    <CardContent>
                      <Typography variant='h6' component='div'>
                        {entry.time}
                      </Typography>
                      <Typography variant='body2'>{entry.description}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              <Grid item xs={12}>
                {Object.keys(entries).length - 1 !== index && (
                  <Divider sx={{ borderBottom: 'solid 2px #cb351a', my: 2 }} />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item container md={4} lg={3} display={{ xs: 'none', md: 'flex' }}>
          <Box
            component='img'
            src='https://s3.amazonaws.com/sportssouth.public/media_assets/rotr/it_bg.jpg'
            alt='Itinerary Background'
            sx={{
              width: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AttendeeItinerary;
